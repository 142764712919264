import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { getToken } from "../../utils/user";
import Breadcrumbs from "../../components/Common/Breadcrumb";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const Register = (props) => {
  const history = useHistory();
  let token = getToken();

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    role: "",
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [roles, setRoles] = useState([]);

  const { firstName, lastName, email, password, mobile, role } = user;

  const handleSubmit = async (event) => {
    event.preventDefault();
    await props.registerUser(user);
    setError(true);
    setSuccess(true);
    setTimeout(() => {
      setError(false);
      setSuccess(false);
    }, 3000);
  };

  const getRoles = async () => {
    let resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/role/all",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (resp.data.message) {
      setRoles(resp.data.roles);
    }
  };

  const redirect = () => {
    if (props.user) {
      history.push("/users");
    }
  };

  useEffect(() => {
    props.registerUserFailed("");
    props.apiError("");
    document.body.classList.add("auth-body-bg");
    getRoles();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Register"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Register", link: "/register" },
            ]}
          />

          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={3}></Col>
                <Col lg={6}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <Col lg={9}>
                          <div>
                            <div className="text-center">
                              <h4 className="font-size-18 mt-4">
                                Register account
                              </h4>
                              <p className="text-muted">
                                Create user account now.
                              </p>
                            </div>

                            {props.user && (
                              <Alert color="success" isOpen={success}>
                                Registration Done Successfully.
                              </Alert>
                            )}
                            {redirect()}

                            {props.registrationError && (
                              <Alert color="danger" isOpen={error}>
                                {props.registrationError}
                              </Alert>
                            )}

                            <div className="p-2 mt-5">
                              <AvForm
                                onValidSubmit={handleSubmit}
                                className="form-horizontal"
                              >
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="firstName">First Name</Label>
                                  <AvField
                                    name="firstName"
                                    value={props.user ? "" : firstName}
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="Enter First Name"
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        firstName: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="lastName">Last Name</Label>
                                  <AvField
                                    name="lastName"
                                    value={props.user ? "" : lastName}
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    placeholder="Enter Last Name"
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        lastName: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-mail-line auti-custom-input-icon"></i>
                                  <Label htmlFor="email">Email</Label>
                                  <AvField
                                    name="email"
                                    value={props.user ? "" : email}
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter Email"
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        email: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="password">Password</Label>
                                  <AvField
                                    name="password"
                                    value={props.user ? "" : password}
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Enter Password"
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        password: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-phone-line auti-custom-input-icon"></i>
                                  <Label htmlFor="mobile">Mobile</Label>
                                  <AvField
                                    name="mobile"
                                    value={props.user ? "" : mobile}
                                    type="text"
                                    className="form-control"
                                    id="mobile"
                                    placeholder="Enter Mobile"
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        mobile: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-medal-line auti-custom-input-icon"></i>
                                  <Label htmlFor="role">Role</Label>
                                  <AvField
                                    name="role"
                                    value={props.user ? "" : role}
                                    type="select"
                                    className="form-control"
                                    id="role"
                                    placeholder="Enter Role"
                                    onChange={(e) =>
                                      setUser({ ...user, role: e.target.value })
                                    }
                                  >
                                    <option>Select Role</option>
                                    {roles &&
                                      roles
                                        .filter(
                                          (r) => r.roleName !== "SuperAdmin"
                                        )
                                        .map((v) => {
                                          return (
                                            <option value={v._id}>
                                              {v.roleName}
                                            </option>
                                          );
                                        })}
                                  </AvField>
                                </FormGroup>

                                <div className="text-center">
                                  <Button
                                    color="primary"
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    {props.loading ? "Loading ..." : "Register"}
                                  </Button>
                                </div>
                              </AvForm>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={3}></Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
