import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Collapse,
  CardHeader,
  Spinner,
  Table,
} from "reactstrap";
import { getToken } from "../../utils/user";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { useParams } from "react-router-dom";

const CustomerProfile = (props) => {
  let token = getToken();

  const [toggle, setToggle] = useState(false);
  const [orders, setOrders] = useState([]);
  const { id } = useParams();
  const getCustomerOrders = async () => {
    let resp = await axios.get(process.env.REACT_APP_BACKEND_URL+`/order/customer/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setOrders(resp.data.orders);
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    getCustomerOrders();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Customer Profile"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Customer Profile", link: "/customerProfile" },
            ]}
          />

          {/* <div className="home-btn d-none d-sm-block">
             <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
         </div>*/}
          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={4}></Col>
                <Col lg={4}>
                  <Card className="pricing-box">
                    <CardBody className="p-4">
                      <div className="text-center">
                        <div className="mt-3">
                          <i className="ri-user-line text-primary h1"></i>
                        </div>
                        <h5 className="mt-4">Profile</h5>

                        <div className="font-size-16 mt-4 pt-2">
                          <ul className="list-unstyled plan-features">
                            <li>
                              Name: {props.location.state.customer.firstName}{" "}
                              {props.location.state.customer.lastName}
                            </li>
                            {
                              <li>
                                Mobile: {props.location.state.customer.mobile}
                              </li>
                            }
                            <li>
                              Email: {props.location.state.customer.email}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}></Col>
                <Col lg={12}>
                  <div id="accordion">
                    <Card className="mb-1">
                      <div
                        onClick={() => {
                          setToggle(!toggle);
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-dark"
                      >
                        <CardHeader id="headingOne">
                          <h6 className="m-0 font-14">
                            Order History
                            <i
                              className={
                                toggle
                                  ? "mdi mdi-minus float-right accor-plus-icon"
                                  : "mdi mdi-plus float-right accor-plus-icon"
                              }
                            ></i>
                          </h6>
                        </CardHeader>
                      </div>
                      <Collapse isOpen={toggle}>
                        <CardBody>
                          <div className="table-responsive">
                            <Table striped className=" mb-0">
                              <thead>
                                <tr>
                                  <th>Order ID</th>
                                  <th>Customer Details</th>
                                  <th>Payment Details</th>
                                  <th>Items</th>
                                  <th>Order Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {false ? (
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <Spinner color="primary" />
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                ) : orders && orders.length === 0 ? (
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <h5>No Order found!</h5>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                ) : (
                                  orders.map((u) => (
                                    <tr>
                                      <td>
                                        <>
                                          {u._id}
                                          <br />
                                        </>
                                      </td>
                                      <td>
                                        UserId: {u.user}
                                        <br />
                                        {u.address.firstName}{" "}
                                        {u.address.lastName}
                                        <br />
                                        {u.address.al1} {u.address.al2}
                                        <br />
                                        {u.address.city} {u.address.pinCode}
                                        <br />
                                        {u.address.state} {u.address.country}
                                        <br />
                                      </td>
                                      <td>
                                        ₹ {u.totalAmount}
                                        <br />
                                        TransactionID: {u.transaction_id}
                                        <br />
                                      </td>

                                      <td>
                                        {u.items.map((i) => (
                                          <>
                                            ProductId:{i.product._id}
                                            <br />
                                            Name: {i.product.name}
                                            <br />
                                            Quantity: {i.quantity}
                                            <br />
                                            {i.selectedProductSize && (
                                              <>
                                                Size: {i.selectedProductSize}
                                                <br />
                                              </>
                                            )}
                                            <span>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    i.selectedProductColor,
                                                  borderRadius: "50%",
                                                  height: 30,
                                                  width: 30,
                                                }}
                                              />
                                            </span>
                                            <br />
                                            <hr />
                                          </>
                                        ))}
                                      </td>
                                      <td>
                                        {u.status}
                                        <br />

                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomerProfile;
