/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { getToken } from "../../utils/user";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FormUpload from "./FormUpload";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";

import { useHistory } from "react-router-dom";

const Testimonial = () => {
  const history = useHistory();
  let token = getToken();

  const [testimonial, setTestimonial] = useState({
    customerName: "",
    content: "",
  });
  const [image, setImage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState("");

  const { customerName, content } = testimonial;

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (image) {
      form.set("image", image);
    }
    try {
      let resp = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/testimonial",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (resp.data.message) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          history.push("/testimonials");
        }, 3000);
        setError(false);
        setTestimonial({
          customerName: "",
          content: "",
        });
        setForm(new FormData());
      }
    } catch (err) {
      setLoading(false);
      setSuccess(false);
      setError(err.response.data.error);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    setForm(new FormData());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Create Testimonial"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Create Testimonial", link: "/testimonial" },
            ]}
          />

          {/* <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>*/}
          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={2}></Col>
                <Col lg={8}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <Col lg={9}>
                          <div>
                            <div className="text-center">
                              {/*  <div>
                                                    <Link to="#" className="logo"><img src={logodark} height="20" alt="logo"/></Link>
                                                </div>*/}

                              <h4 className="font-size-18 mt-4">
                                Create Testimonial
                              </h4>
                            </div>

                            {success && (
                              <Alert color="success">
                                Testimonial created Successfully.
                              </Alert>
                            )}

                            {error && <Alert color="danger">{error}</Alert>}

                            <div className="p-2 mt-5">
                              <AvForm
                                onValidSubmit={handleSubmit}
                                className="form-horizontal"
                              >
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-file-list-line auti-custom-input-icon"></i>
                                  <Label htmlFor="customerName">
                                    Customer Name
                                  </Label>
                                  <AvField
                                    name="customerName"
                                    value={customerName}
                                    type="text"
                                    className="form-control"
                                    id="customerName"
                                    placeholder="Enter Customer Name"
                                    onChange={(e) => {
                                      form.set("customerName", e.target.value);
                                      setTestimonial({
                                        ...testimonial,
                                        customerName: e.target.value,
                                      });
                                    }}
                                  />
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i
                                    className="ri-layout-6-line auti-custom-input-icon"
                                    style={{ top: "25%" }}
                                  ></i>
                                  <Label htmlFor="content">Content</Label>
                                  <AvField
                                    name="content"
                                    value={content}
                                    type="textarea"
                                    className="form-control"
                                    id="content"
                                    placeholder="Enter Testimonial Content"
                                    onChange={(e) => {
                                      form.set("content", e.target.value);
                                      setTestimonial({
                                        ...testimonial,
                                        content: e.target.value,
                                      });
                                    }}
                                    style={{ height: 120 }}
                                  />
                                </FormGroup>

                                <FormUpload setImage={setImage} />

                                <div className="text-center">
                                  <Button
                                    color="primary"
                                    className="mt-1 w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    {loading ? "Loading ..." : "Create"}
                                  </Button>
                                </div>

                                {/*<div className="mt-4 text-center">
                                                        <p className="mb-0">By registering you agree to the Template <Link to="#" className="text-primary">Terms of Use</Link></p>
                                                    </div>*/}
                              </AvForm>
                            </div>

                            {/*<div className="mt-5 text-center">
                                                <p>Already have an account ? <Link to="/login" className="font-weight-medium text-primary"> Login</Link> </p>
                                                <p>© 2020 Template. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                            </div>*/}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={2}></Col>
                {/*<Col lg={7}>
                        <div className="authentication-bg">
                            <div className="bg-overlay"></div>
                        </div>
                    </Col>*/}
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Testimonial;
