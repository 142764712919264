/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Alert,
  Spinner,
} from "reactstrap";
import { getToken } from "../../utils/user";
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import axios from "axios";

const ViewRole = () => {
  let token = getToken();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState({
    _id: "",
    roleName: "",
    product: [],
    category: [],
    order: [],
    customer: [],
    orderStatus: [],
    cart: [],
    eyeTest: [],
  });
  const [editModal, setEditModal] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateRole, setUpdateRole] = useState("");
  const { _id, roleName } = role;
  const getRoles = async () => {
    setLoading(true);
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/role/all",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      setLoading(false);
      setRoles(resp.data.roles);
    }
  };

  const handleSubmit = async (event, errors, values) => {
    setEditLoading(true);
    try {
      let resp = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/role/update/${_id}`,
        values,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setEditLoading(false);
      if (resp.data.message) {
        setEditError(false);
        setRole({
          _id: "",
          roleName: "",
          product: [],
          category: [],
          order: [],
          customer: [],
          orderStatus: [],
          cart: [],
          eyeTest: [],
        });
        getRoles();
        setEditModal(false);
      }
    } catch (err) {
      setEditLoading(false);
      setEditError(err.response.data.error);
    }
  };

  const getPermissions = async () => {
    let resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/role/permissions",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      setPermissions(resp.data.permissions);
    }
  };
  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    getRoles();
    getPermissions();
  }, []);

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={editModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setEditModal(false)}>Edit Role</ModalHeader>
        <ModalBody>
          {editError && <Alert color="danger">{editError}</Alert>}

          <div className="p-2 mt-3">
            <AvForm
              onSubmit={handleSubmit}
              className="form-horizontal"
              model={role}
            >
              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-medal-line auti-custom-input-icon"></i>
                <Label htmlFor="roleName">Role Name</Label>
                <AvField
                  name="roleName"
                  value={roleName}
                  type="text"
                  className="form-control"
                  id="roleName"
                  placeholder="Enter Role Name"
                  onChange={(e) =>
                    setRole({ ...role, roleName: e.target.value })
                  }
                />
              </FormGroup>

              <Row>
                {permissions &&
                  permissions.map((p, i) => {
                    if (p.resource !== "orderStatus") {
                      return (
                        <Col
                          lg={6}
                          className="mb-1"
                          style={
                            i % 2 !== 0
                              ? {
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                }
                              : {
                                  textAlign: "left",
                                }
                          }
                        >
                          <Label htmlFor={p.resource}>
                            {p.resource.charAt(0).toUpperCase() +
                              p.resource.slice(1)}
                          </Label>
                          <AvCheckboxGroup name={p.resource}>
                            {p.actions.map((a) => (
                              <AvCheckbox label={a.name} value={a.id} />
                            ))}
                          </AvCheckboxGroup>
                        </Col>
                      );
                    } else {
                      return (
                        <Col lg={12} className="mb-1">
                          <Label htmlFor={p.resource}>Order Status</Label>
                          <AvCheckboxGroup name={p.resource}>
                            <Row>
                              {p.actions.map((a, index) => {
                                if (index <= 8) {
                                  return (
                                    <Col lg={6}>
                                      {" "}
                                      <AvCheckbox label={a.name} value={a.id} />
                                    </Col>
                                  );
                                } else {
                                  return (
                                    <Col lg={6}>
                                      {" "}
                                      <AvCheckbox label={a.name} value={a.id} />
                                    </Col>
                                  );
                                }
                              })}
                            </Row>
                          </AvCheckboxGroup>
                        </Col>
                      );
                    }
                  })}
              </Row>

              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {editLoading ? "Loading ..." : "Edit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={updateModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setUpdateModal(false)}>
          Update User Role
        </ModalHeader>
        <ModalBody>
          <div className="p-2 mt-3">
            <AvForm
              onSubmit={async () => {
                setUpdateLoading(true);
                try {
                  let resp = await axios.put(
                    process.env.REACT_APP_BACKEND_URL +
                      `/role/update/user/${_id}`,
                    { role: updateRole },
                    { headers: { Authorization: `Bearer ${token}` } }
                  );
                  setUpdateLoading(false);
                  if (resp.data.message) {
                    setRole({
                      _id: "",
                      roleName: "",
                      product: [],
                      category: [],
                      order: [],
                      customer: [],
                      cart: [],
                      eyeTest: [],
                    });
                    setUpdateRole("");
                    getRoles();
                    setUpdateModal(false);
                  }
                } catch (err) {
                  console.log(err);
                }
              }}
              className="form-horizontal"
            >
              <h5>
                This role is associated to users, please replace it with other
                role.
              </h5>
              <FormGroup className="auth-form-group-custom mt-2 mb-4">
                <i className="ri-store-line auti-custom-input-icon"></i>
                <Label htmlFor="role">Role</Label>
                <AvField
                  name="role"
                  value={role}
                  type="select"
                  className="form-control"
                  id="role"
                  placeholder="Enter Role"
                  onChange={(e) => setUpdateRole(e.target.value)}
                >
                  <option>Select Role</option>
                  {roles &&
                    roles
                      .filter(
                        (a) => a._id !== _id && a.roleName !== "SuperAdmin"
                      )
                      .map((f) => {
                        return <option value={f._id}>{f.roleName}</option>;
                      })}
                </AvField>
              </FormGroup>

              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {updateLoading ? "Loading ..." : "Update"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={deleteModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setDeleteModal(false)}>
          Delete Role
        </ModalHeader>
        <ModalBody>
          <div className="p-2 mt-3">
            <AvForm
              onValidSubmit={async () => {
                setDeleteLoading(true);
                try {
                  let resp = await axios.delete(
                    process.env.REACT_APP_BACKEND_URL + `/role/delete/${_id}`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  setDeleteLoading(false);
                  if (resp.data.message) {
                    setRole({
                      _id: "",
                      roleName: "",
                      product: [],
                      category: [],
                      order: [],
                      customer: [],
                      cart: [],
                      eyeTest: [],
                    });
                    getRoles();
                    setDeleteModal(false);
                  }
                } catch (err) {
                  setDeleteLoading(false);
                  setDeleteModal(false);
                  setUpdateModal(true);
                }
              }}
              className="form-horizontal"
            >
              <h4>Are you sure you want to delete this role?</h4>

              <div className="text-center">
                <Button
                  color="danger"
                  className="mt-1 w-md waves-effect waves-light mr-2"
                  type="submit"
                >
                  {deleteLoading ? "Loading ..." : "Delete"}
                </Button>
                <Button
                  color="dark"
                  className="mt-1 w-md waves-effect waves-light"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="View Roles"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "View Roles", link: "/roles" },
            ]}
          />

          {/* <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>*/}
          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                {/*}<Col lg={1}></Col>*/}
                <Col lg={12}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <div className="table-responsive">
                          <Table striped className=" mb-0">
                            <thead>
                              <tr>
                                <th>Sr no.</th>
                                <th>Role Name</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <tr>
                                  <th></th>
                                  <th>
                                    <Spinner color="primary" />
                                  </th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              ) : (
                                roles &&
                                roles
                                  .filter((r) => r.roleName !== "SuperAdmin")
                                  .map((f, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{f.roleName}</td>
                                      <td>
                                        <Button
                                          color="warning"
                                          type="button"
                                          outline
                                          className="waves-effect waves-light mr-1"
                                          onClick={() => {
                                            setEditModal(true);
                                            setRole(f);
                                          }}
                                        >
                                          <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                          Edit
                                        </Button>
                                      </td>
                                      <td>
                                        <Button
                                          color="danger"
                                          type="button"
                                          outline
                                          className="waves-effect waves-light mr-1"
                                          onClick={() => {
                                            setDeleteModal(true);
                                            setRole(f);
                                          }}
                                        >
                                          <i className="ri-delete-bin-fill align-middle mr-2"></i>{" "}
                                          Delete
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={1}></Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewRole;
