import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ResetPassword from "../pages/Authentication/ResetPassword";

//User pages
import Register from "../pages/User/Register";
import Users from "../pages/User/Users";

//Role pages
import Role from "../pages/Role/Role";
import ViewRole from "../pages/Role/ViewRole";

// Category pages
import Category from "../pages/Category/Category";
import ViewCategory from "../pages/Category/ViewCategory";

// Product pages
import Products from "../pages/Product/Products";
import ViewProduct from "../pages/Product/ViewProduct";

// Customer Pages
import ViewCustomers from "../pages/Customer/ViewCustomers";
import CustomerProfile from "../pages/Customer/CustomerProfile";

// Order pages
import ViewOrders from "../pages/Order/ViewOrders";

import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import AddProduct from "../pages/Product/AddProduct";

import Coupon from "../pages/Coupon/Coupon";
import ViewCoupon from "../pages/Coupon/ViewCoupon";

import Banner from "../pages/Banner/Banner";
import Logo from "../pages/Logo/Logo";
import Theme from "../pages/Theme/Theme";

import Testimonial from "../pages/Testimonial/Testimonial";
import ViewTestimonial from "../pages/Testimonial/ViewTestimonial";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const superAdminRoutes = [
  { path: "/register", component: Register, config: "displayRole" },
  { path: "/users", component: Users, config: "displayRole" },
  { path: "/role", component: Role, config: "displayRole" },
  { path: "/roles", component: ViewRole, config: "displayRole" },
  { path: "/coupon", component: Coupon, config: "displayCoupon" },
  { path: "/coupons", component: ViewCoupon, config: "displayCoupon" },
  { path: "/banner", component: Banner },
  { path: "/logo", component: Logo },
  {
    path: "/testimonial",
    component: Testimonial,
    config: "isDynamicTestimonial",
  },
  {
    path: "/testimonials",
    component: ViewTestimonial,
    config: "isDynamicTestimonial",
  },
  {
    path: "/theme",
    component: Theme,
    config: "isDynamicTheme",
  },
];

const permissionRoutes = [
  { path: "/category", component: Category, permit: "Create Category" },
  { path: "/categories", component: ViewCategory, permit: "View Categories" },
  {
    path: `/productDetails/:id`,
    component: ViewProduct,
    permit: "View Products",
  },
  { path: "/product", component: AddProduct, permit: "Create Product" },
  // Test route
  { path: `/products`, component: Products, permit: "View Products" },

  { path: `/customers`, component: ViewCustomers, permit: "View Customers" },
  {
    path: `/customerProfile/:id`,
    component: CustomerProfile,
    permit: "View Customers",
  },
  { path: `/orders`, component: ViewOrders, permit: "View Orders" },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/resetPassword/:token", component: ResetPassword },
];

export {
  superAdminRoutes,
  authProtectedRoutes,
  permissionRoutes,
  publicRoutes,
};
