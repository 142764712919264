/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Alert,
  Input,
  Form,
  Spinner,
} from "reactstrap";
import { getToken, isPermitted, getRole } from "../../utils/user";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import axios from "axios";

import { useHistory } from "react-router-dom";

const ViewCustomers = () => {
  let token = getToken();
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    mobile: "",
    isPremium: "",
  });
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [filters, setFilters] = useState("firstName");
  const [filterFranchise, setFilterFranchise] = useState(false);
  const [filterAccount, setFilterAccount] = useState(false);
  const { _id, firstName, lastName, mobile, isPremium } = customer;
  const getCustomers = async () => {
    setLoading(true);
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/customer/all",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      setLoading(false);
      setCustomers(resp.data.customers);
    }
  };

  const handleSubmit = async (id) => {
    setEditLoading(true);
    try {
      let resp = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/customer/update/${id}`,
        customer,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setEditLoading(false);
      if (resp.data.message) {
        setEditError(false);
        setCustomer({
          _id: "",
          firstName: "",
          lastName: "",
          mobile: "",
          isPremium: "",
        });
        getCustomers();
        setEditModal(false);
      }
    } catch (err) {
      setEditLoading(false);
      setEditError(err.response.data.error);
    }
  };
  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    getCustomers();
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={editModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setEditModal(false)}>
          Edit Customer Details
        </ModalHeader>
        <ModalBody>
          {editError && <Alert color="danger">{editError}</Alert>}

          <div className="p-2 mt-3">
            <AvForm
              onValidSubmit={() => handleSubmit(_id)}
              className="form-horizontal"
            >
              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-user-2-line auti-custom-input-icon"></i>
                <Label htmlFor="firstName">First Name</Label>
                <AvField
                  name="firstName"
                  value={firstName}
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter First Name"
                  onChange={(e) =>
                    setCustomer({ ...customer, firstName: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-user-2-line auti-custom-input-icon"></i>
                <Label htmlFor="lastName">Last Name</Label>
                <AvField
                  name="lastName"
                  value={lastName}
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter Last Name"
                  onChange={(e) =>
                    setCustomer({ ...customer, lastName: e.target.value })
                  }
                />
              </FormGroup>
              {isPermitted("View Customer Mobile No.") && (
                <FormGroup className="auth-form-group-custom mb-4">
                  <i className="ri-phone-line auti-custom-input-icon"></i>
                  <Label htmlFor="mobile">Mobile No.</Label>
                  <AvField
                    name="mobile"
                    value={mobile}
                    type="text"
                    className="form-control"
                    id="mobile"
                    placeholder="Enter Mobile No."
                    onChange={(e) =>
                      setCustomer({ ...customer, mobile: e.target.value })
                    }
                  />
                </FormGroup>
              )}
              {getRole() === "SuperAdmin" && (
                <FormGroup className="auth-form-group-custom mb-4">
                  <div className="custom-control custom-switch mb-2" dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input p-3"
                      id="customSwitch2"
                      defaultChecked={isPremium}
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="customSwitch2"
                      onClick={(e) =>
                        setCustomer({ ...customer, isPremium: !isPremium })
                      }
                    >
                      Toggle the switch to change premium account setting
                    </Label>
                  </div>
                </FormGroup>
              )}

              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {editLoading ? "Loading ..." : "Edit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="View Customers"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "View Customers", link: "/customers" },
            ]}
          />

          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                {/*}<Col lg={1}></Col>*/}
                <Col lg={12}>
                  <Row>
                    <Col xl={4} md={12}>
                      <Form className="app-search">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            style={{ width: "100%", paddingLeft: "40%" }}
                            onChange={async (e) => {
                              setLoading(true);
                              setKeyword(e.target.value);
                              let resp = await axios.post(
                                process.env.REACT_APP_BACKEND_URL +
                                  `/customer/filter`,
                                {
                                  filters,
                                  keyword: e.target.value,
                                  filterFranchise,
                                  filterAccount,
                                },
                                {
                                  headers: { Authorization: `Bearer ${token}` },
                                }
                              );
                              if (resp.data.message) {
                                setLoading(false);
                                setCustomers(resp.data.customers);
                              }
                            }}
                          />
                          <span>
                            <Input
                              type="select"
                              onChange={(e) => setFilters(e.target.value)}
                            >
                              <option value="firstName">FirstName</option>
                              <option value="lastName">LastName</option>
                              <option value="mobile">Mobile</option>
                              <option value="email">Email</option>
                            </Input>
                          </span>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <div className="table-responsive">
                          <Table striped className=" mb-0">
                            <thead>
                              <tr>
                                <th>Sr no.</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Mobile</th>
                                <th>Email</th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <Spinner color="primary" />
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              ) : customers ? (
                                customers.length === 0 ? (
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <h5>No customer found</h5>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  customers.map((f, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{f.firstName}</td>
                                      <td>{f.lastName}</td>
                                      <td>{f.mobile}</td>
                                      <td>{f.email}</td>
                                      <td>
                                        <Button
                                          color="info"
                                          type="button"
                                          outline
                                          className="waves-effect waves-light mr-1"
                                          onClick={() => {
                                            history.push({
                                              pathname: `/customerProfile/${f._id}`,
                                              state: { customer: f },
                                            });
                                          }}
                                        >
                                          <i className="ri-eye-fill align-middle mr-2"></i>{" "}
                                          View
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                )
                              ) : null}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
                {/*}  <Col lg={1}></Col>*/}
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewCustomers;
