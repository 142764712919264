import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  getRole,
  getToken,
  hasResource,
  isPermitted,
  verifyConfig,
} from "../../utils/user";
import axios from "axios";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getCategories();
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
        this.getCategories();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  async getCategories() {
    let token = getToken();
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/category/fetch",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (resp.data.message) {
      this.setState({ categories: resp.data.categories });
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{"Menu"}</li>

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>

                <span className="ml-1">{"Dashboard"}</span>
              </Link>
            </li>

            {getRole() === "SuperAdmin" && verifyConfig("displayRole") && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-account-circle-line"></i>
                  <span className="ml-1">{"User"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/register">{"Register"}</Link>
                  </li>
                  <li>
                    <Link to="/users">{"View Users"}</Link>
                  </li>
                </ul>
              </li>
            )}

            {getRole() === "SuperAdmin" && verifyConfig("displayRole") && (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-medal-line"></i>
                    <span className="ml-1">{"Role"}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/role">{"Create Role"}</Link>
                    </li>
                    <li>
                      <Link to="/roles">{"View Roles"}</Link>
                    </li>
                  </ul>
                </li>
              </>
            )}

            {hasResource("category") && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-file-list-line"></i>
                  <span className="ml-1">{"Category"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {isPermitted("Create Category") && (
                    <li>
                      <Link to="/category">{"Create Category"}</Link>
                    </li>
                  )}
                  {isPermitted("View Categories") && (
                    <li>
                      <Link to="/categories">{"View Categories"}</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {isPermitted("Create Product") && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-bubble-chart-line"></i>
                  <span className="ml-1">{"Product"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {isPermitted("Create Product") && (
                    <li>
                      <Link to="/product">{"Add Product"}</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {isPermitted("View Products") && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-store-3-line"></i>
                  <span className="ml-1">{"Inventory"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/products">{"View Products"}</Link>
                  </li>
                </ul>
              </li>
            )}
            {hasResource("customer") && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-user-2-line"></i>
                  <span className="ml-1">{"Customer"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {isPermitted("View Customers") && (
                    <li>
                      <Link to="/customers">{"View Customers"}</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {hasResource("order") && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-shopping-bag-2-line"></i>
                  <span className="ml-1">{"Order"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {isPermitted("View Orders") && (
                    <li>
                      <Link to="/orders">{"View Orders"}</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {getRole() === "SuperAdmin" && verifyConfig("displayCoupon") && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-coupon-line"></i>
                  <span className="ml-1">{"Coupon"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/coupon">{"Create Coupon"}</Link>
                  </li>
                  <li>
                    <Link to="/coupons">{"View Coupons"}</Link>
                  </li>
                </ul>
              </li>
            )}
            {getRole() === "SuperAdmin" && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-image-add-line"></i>
                  <span className="ml-1">{"Banner"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/banner">{"Update Banners"}</Link>
                  </li>
                </ul>
              </li>
            )}
            {getRole() === "SuperAdmin" && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-apps-line"></i>
                  <span className="ml-1">{"Logo"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/logo">{"Update Logo"}</Link>
                  </li>
                </ul>
              </li>
            )}
            {getRole() === "SuperAdmin" && verifyConfig("isDynamicTheme") && (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-palette-line"></i>
                    <span className="ml-1">{"Theme"}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/theme">{"Update Theme"}</Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            {getRole() === "SuperAdmin" &&
              verifyConfig("isDynamicTestimonial") && (
                <>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="ri-chat-smile-2-line"></i>
                      <span className="ml-1">{"Testimonial"}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/testimonial">{"Create Testimonial"}</Link>
                      </li>
                      <li>
                        <Link to="/testimonials">{"View Testimonials"}</Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(SidebarContent)
);
