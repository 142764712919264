import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
  CardHeader,
  Card,
  Collapse,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  Spinner,
  CardFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { getToken, verifyConfig } from "../../utils/user";
import FormUpload from "./FormUpload";

const Banner = (props) => {
  const history = useHistory();
  let token = getToken();
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState({
    heroSlider: true,
    bannerOne: false,
    bannerTwo: false,
  });

  const [heroModal, setHeroModal] = useState(false);
  const [deleteHeroModal, setDeleteHeroModal] = useState(false);
  const [heroLoading, setHeroLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [heroError, setHeroError] = useState(false);
  const [heroSlide, setHeroSlide] = useState({
    bannerType: "",
    type: "",
    index: 0,
    subtitle: "",
  });
  const [heroImage, setHeroImage] = useState(null);

  const [banners, setBanners] = useState(null);

  const fetchBanners = async () => {
    setLoading(true);
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/banner",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (resp.data.message) {
      setBanners(resp.data.banners);

      setLoading(false);
    }
  };

  const handleHeroSubmit = async () => {
    setHeroLoading(true);
    // if (!heroSlide.subtitle || heroSlide.subtitle === "") {
    //   setHeroLoading(false);
    //   setHeroError("Slide title is required");
    //   return;
    // }
    if (heroSlide.type === "Add" && !heroImage) {
      setHeroLoading(false);
      setHeroError("Image is required field");
      return;
    }

    let form = new FormData();
    form.set("subtitle", heroSlide.subtitle);
    form.set("type", heroSlide.type);
    if (heroSlide.type === "Update") form.set("index", heroSlide.index);
    if (heroImage) form.set("image", heroImage);

    let resp = await axios.put(
      process.env.REACT_APP_BACKEND_URL + "/banner/heroslide",
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data.message) {
      setHeroLoading(false);
      setHeroError(false);
      setHeroSlide({
        bannerType: "",
        type: "",
        index: 0,
        subtitle: "",
      });
      setHeroImage(null);
      setHeroModal(false);
      setTimeout(() => {
        window.location.replace("/banner");
      }, 3000);
    }
  };

  const handleBannerOneSubmit = async () => {
    setHeroLoading(true);
    if (!heroImage) {
      setHeroLoading(false);
      setHeroError("Image is required field");
      return;
    }

    let form = new FormData();
    form.set("type", heroSlide.type);
    if (heroImage) form.set("image", heroImage);

    let resp = await axios.put(
      process.env.REACT_APP_BACKEND_URL + "/banner/bannerone",
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data.message) {
      setHeroLoading(false);
      setHeroError(false);
      setHeroSlide({
        bannerType: "",
        type: "",
        index: 0,
        subtitle: "",
      });
      setHeroImage(null);
      setHeroModal(false);
      setTimeout(() => {
        window.location.replace("/banner");
      }, 3000);
    }
  };

  const handleBannerTwoSubmit = async () => {
    setHeroLoading(true);
    // if (!heroSlide.subtitle || heroSlide.subtitle === "") {
    //   setHeroLoading(false);
    //   setHeroError("Slide title is required");
    //   return;
    // }
    if (!heroImage) {
      setHeroLoading(false);
      setHeroError("Image is required field");
      return;
    }

    let form = new FormData();
    form.set("subtitle", heroSlide.subtitle);
    form.set("type", heroSlide.type);
    if (heroImage) form.set("image", heroImage);

    let resp = await axios.put(
      process.env.REACT_APP_BACKEND_URL + "/banner/bannertwo",
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data.message) {
      setHeroLoading(false);
      setHeroError(false);
      setHeroSlide({
        bannerType: "",
        type: "",
        index: 0,
        subtitle: "",
      });
      setHeroImage(null);
      setHeroModal(false);
      setTimeout(() => {
        window.location.replace("/banner");
      }, 3000);
    }
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    fetchBanners();
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={heroModal} centered={true} scrollable={true} size="xl">
        <ModalHeader toggle={() => setHeroModal(false)}>Add Slide</ModalHeader>
        <ModalBody>
          {heroError && <Alert color="danger">{heroError}</Alert>}

          <div className="p-2 mt-3">
            <AvForm
              onSubmit={() => {
                if (heroSlide.bannerType === "HeroSlider") {
                  handleHeroSubmit();
                } else if (heroSlide.bannerType === "BannerOne") {
                  handleBannerOneSubmit();
                } else if (heroSlide.bannerType === "BannerTwo") {
                  handleBannerTwoSubmit();
                }
              }}
              className="form-horizontal"
            >
              {heroSlide.bannerType !== "BannerOne" && (
                <FormGroup className="auth-form-group-custom mb-4">
                  <i className="ri-text auti-custom-input-icon"></i>
                  <Label htmlFor="title">Title</Label>
                  <AvField
                    name="title"
                    value={heroSlide.subtitle}
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Enter Slide Title"
                    onChange={(e) =>
                      setHeroSlide({ ...heroSlide, subtitle: e.target.value })
                    }
                  />
                </FormGroup>
              )}
              <FormUpload setImage={setHeroImage} />
              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {heroLoading ? "Loading ..." : "Add"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={deleteHeroModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setDeleteHeroModal(false)}>
          Delete Slide
        </ModalHeader>
        <ModalBody>
          <div className="p-2 mt-3">
            <AvForm
              onValidSubmit={async () => {
                setDeleteLoading(true);
                let form = new FormData();
                form.set("index", heroSlide.index);
                form.set("type", heroSlide.type);
                try {
                  let resp = await axios.put(
                    process.env.REACT_APP_BACKEND_URL + "/banner/heroslide",
                    form,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  setDeleteLoading(false);
                  if (resp.data.message) {
                    setHeroSlide({
                      bannerType: "",
                      type: "",
                      index: 0,
                      subtitle: "",
                    });
                    fetchBanners();
                    setDeleteHeroModal(false);
                  }
                } catch (err) {
                  setDeleteLoading(false);
                  setDeleteHeroModal(false);
                }
              }}
              className="form-horizontal"
            >
              <h4>Are you sure you want to delete this slide?</h4>

              <div className="text-center">
                <Button
                  color="danger"
                  className="mt-1 w-md waves-effect waves-light mr-2"
                  type="submit"
                >
                  {deleteLoading ? "Loading ..." : "Delete"}
                </Button>
                <Button
                  color="dark"
                  className="mt-1 w-md waves-effect waves-light"
                  onClick={() => setDeleteHeroModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Update Banners"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Update Banners", link: "/banner" },
            ]}
          />

          {/* <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>*/}
          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={1}></Col>
                <Col lg={10}>
                  <div id="accordion">
                    <Card className="mb-1">
                      <div
                        onClick={() => {
                          setToggle({
                            heroSlider: !toggle.heroSlider,
                            bannerOne: false,
                            bannerTwo: false,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-dark"
                      >
                        <CardHeader id="headingOne">
                          <h6 className="m-0 font-14">
                            Hero Slider
                            <i
                              className={
                                toggle.heroSlider
                                  ? "mdi mdi-minus float-right accor-plus-icon"
                                  : "mdi mdi-plus float-right accor-plus-icon"
                              }
                            ></i>
                          </h6>
                        </CardHeader>
                      </div>
                      <Collapse isOpen={toggle.heroSlider}>
                        <CardBody>
                          {loading ? (
                            <Row>
                              <Col style={{ textAlign: "center" }}>
                                <Spinner color="primary" />
                              </Col>
                            </Row>
                          ) : (
                            <>
                              {verifyConfig("isDynamicBanner") && (
                                <Row>
                                  <Col className="d-flex justify-content-end align-items-center">
                                    <Button
                                      color="primary"
                                      type="button"
                                      className="waves-effect waves-light mr-1"
                                      disabled={
                                        !banners
                                          ? false
                                          : banners.heroSlider.length >= 5
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        setHeroSlide({
                                          ...heroSlide,
                                          bannerType: "HeroSlider",
                                          type: "Add",
                                        });
                                        setHeroModal(true);
                                      }}
                                    >
                                      <i className="ri-add-fill align-middle mr-1"></i>{" "}
                                      Slide
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                {banners &&
                                  banners.heroSlider.map((h, i) => (
                                    <>
                                      <Col lg="6" className="mt-3">
                                        <Card>
                                          <img
                                            alt="banner image"
                                            src={h.image}
                                          />
                                          <CardBody>
                                            <CardTitle tag="h5">
                                              {h.subtitle}
                                            </CardTitle>
                                          </CardBody>
                                          {verifyConfig("isDynamicBanner") && (
                                            <CardFooter>
                                              <Button
                                                color="warning"
                                                type="button"
                                                outline
                                                className="waves-effect waves-light mr-3"
                                                onClick={() => {
                                                  setHeroSlide({
                                                    ...heroSlide,
                                                    bannerType: "HeroSlider",
                                                    index: i,
                                                    type: "Update",
                                                    subtitle: h.subtitle,
                                                  });
                                                  setHeroModal(true);
                                                }}
                                              >
                                                <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                                Edit
                                              </Button>

                                              <Button
                                                color="danger"
                                                type="button"
                                                outline
                                                className="waves-effect waves-light mr-1"
                                                onClick={() => {
                                                  setHeroSlide({
                                                    ...heroSlide,
                                                    bannerType: "HeroSlider",
                                                    index: i,
                                                    type: "Delete",
                                                  });
                                                  setDeleteHeroModal(true);
                                                }}
                                              >
                                                <i className="ri-delete-bin-fill align-middle mr-2"></i>{" "}
                                                Delete
                                              </Button>
                                            </CardFooter>
                                          )}
                                        </Card>
                                      </Col>
                                    </>
                                  ))}
                              </Row>
                            </>
                          )}
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="mb-1">
                      <div
                        onClick={() => {
                          setToggle({
                            heroSlider: false,
                            bannerOne: !toggle.bannerOne,
                            bannerTwo: false,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-dark"
                      >
                        <CardHeader id="headingOne">
                          <h6 className="m-0 font-14">
                            Banner-1
                            <i
                              className={
                                toggle.bannerOne
                                  ? "mdi mdi-minus float-right accor-plus-icon"
                                  : "mdi mdi-plus float-right accor-plus-icon"
                              }
                            ></i>
                          </h6>
                        </CardHeader>
                      </div>
                      <Collapse isOpen={toggle.bannerOne}>
                        <CardBody>
                          {loading ? (
                            <Row>
                              <Col style={{ textAlign: "center" }}>
                                <Spinner color="primary" />
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <Row>
                                {banners &&
                                banners.bannerOne &&
                                banners.bannerOne.left ? (
                                  <Col lg="6" className="mt-3">
                                    <Card>
                                      <img
                                        alt="banner image"
                                        src={banners.bannerOne.left}
                                      />
                                      <CardBody></CardBody>
                                      {verifyConfig("isDynamicBanner") && (
                                        <CardFooter>
                                          <Button
                                            color="warning"
                                            type="button"
                                            outline
                                            className="waves-effect waves-light mr-3"
                                            onClick={() => {
                                              setHeroSlide({
                                                ...heroSlide,
                                                bannerType: "BannerOne",

                                                type: "left",
                                              });
                                              setHeroModal(true);
                                            }}
                                          >
                                            <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                            Edit
                                          </Button>
                                        </CardFooter>
                                      )}
                                    </Card>
                                  </Col>
                                ) : (
                                  <>
                                    {verifyConfig("isDynamicBanner") && (
                                      <Col
                                        lg="6"
                                        className="mt-3"
                                        style={{ textAlign: "center" }}
                                      >
                                        <Button
                                          color="primary"
                                          type="button"
                                          className="waves-effect waves-light mr-1"
                                          onClick={() => {
                                            setHeroSlide({
                                              ...heroSlide,
                                              bannerType: "BannerOne",
                                              type: "left",
                                            });
                                            setHeroModal(true);
                                          }}
                                        >
                                          <i className="ri-add-fill align-middle mr-1"></i>{" "}
                                          Left Banner
                                        </Button>
                                      </Col>
                                    )}
                                  </>
                                )}
                                {banners &&
                                banners.bannerOne &&
                                banners.bannerOne.right ? (
                                  <Col lg="6" className="mt-3">
                                    <Card>
                                      <img
                                        alt="banner image"
                                        src={banners.bannerOne.right}
                                      />
                                      <CardBody></CardBody>
                                      {verifyConfig("isDynamicBanner") && (
                                        <CardFooter>
                                          <Button
                                            color="warning"
                                            type="button"
                                            outline
                                            className="waves-effect waves-light mr-3"
                                            onClick={() => {
                                              setHeroSlide({
                                                ...heroSlide,
                                                bannerType: "BannerOne",

                                                type: "right",
                                              });
                                              setHeroModal(true);
                                            }}
                                          >
                                            <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                            Edit
                                          </Button>
                                        </CardFooter>
                                      )}
                                    </Card>
                                  </Col>
                                ) : (
                                  <>
                                    {verifyConfig("isDynamicBanner") && (
                                      <Col
                                        lg="6"
                                        className="mt-3"
                                        style={{ textAlign: "center" }}
                                      >
                                        <Button
                                          color="primary"
                                          type="button"
                                          className="waves-effect waves-light mr-1"
                                          onClick={() => {
                                            setHeroSlide({
                                              ...heroSlide,
                                              bannerType: "BannerOne",
                                              type: "right",
                                            });
                                            setHeroModal(true);
                                          }}
                                        >
                                          <i className="ri-add-fill align-middle mr-1"></i>{" "}
                                          Right Banner
                                        </Button>
                                      </Col>
                                    )}
                                  </>
                                )}
                              </Row>
                            </>
                          )}
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="mb-1">
                      <div
                        onClick={() => {
                          setToggle({
                            heroSlider: false,
                            bannerOne: false,
                            bannerTwo: !toggle.bannerTwo,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-dark"
                      >
                        <CardHeader id="headingOne">
                          <h6 className="m-0 font-14">
                            Banner-2
                            <i
                              className={
                                toggle.bannerTwo
                                  ? "mdi mdi-minus float-right accor-plus-icon"
                                  : "mdi mdi-plus float-right accor-plus-icon"
                              }
                            ></i>
                          </h6>
                        </CardHeader>
                      </div>
                      <Collapse isOpen={toggle.bannerTwo}>
                        <CardBody>
                          {loading ? (
                            <Row>
                              <Col style={{ textAlign: "center" }}>
                                <Spinner color="primary" />
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <Row>
                                {banners &&
                                banners.bannerTwo &&
                                banners.bannerTwo.left ? (
                                  <Col lg="6" className="mt-3">
                                    <Card>
                                      <img
                                        alt="banner image"
                                        src={banners.bannerTwo.left.image}
                                      />
                                      <CardBody>
                                        <CardTitle tag="h5">
                                          {banners.bannerTwo.left.subtitle}
                                        </CardTitle>
                                      </CardBody>
                                      {verifyConfig("isDynamicBanner") && (
                                        <CardFooter>
                                          <Button
                                            color="warning"
                                            type="button"
                                            outline
                                            className="waves-effect waves-light mr-3"
                                            onClick={() => {
                                              setHeroSlide({
                                                ...heroSlide,
                                                bannerType: "BannerTwo",
                                                subtitle:
                                                  banners.bannerTwo.left
                                                    .subtitle,
                                                type: "left",
                                              });
                                              setHeroModal(true);
                                            }}
                                          >
                                            <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                            Edit
                                          </Button>
                                        </CardFooter>
                                      )}
                                    </Card>
                                  </Col>
                                ) : (
                                  <>
                                    {verifyConfig("isDynamicBanner") && (
                                      <Col
                                        lg="6"
                                        className="mt-3"
                                        style={{ textAlign: "center" }}
                                      >
                                        <Button
                                          color="primary"
                                          type="button"
                                          className="waves-effect waves-light mr-1"
                                          onClick={() => {
                                            setHeroSlide({
                                              ...heroSlide,
                                              bannerType: "BannerTwo",
                                              type: "left",
                                            });
                                            setHeroModal(true);
                                          }}
                                        >
                                          <i className="ri-add-fill align-middle mr-1"></i>{" "}
                                          Left Banner
                                        </Button>
                                      </Col>
                                    )}
                                  </>
                                )}
                                {banners &&
                                banners.bannerTwo &&
                                banners.bannerTwo.right ? (
                                  <Col lg="6" className="mt-3">
                                    <Card>
                                      <img
                                        alt="banner image"
                                        src={banners.bannerTwo.right.image}
                                      />
                                      <CardBody>
                                        <CardTitle tag="h5">
                                          {banners.bannerTwo.right.subtitle}
                                        </CardTitle>
                                      </CardBody>
                                      {verifyConfig("isDynamicBanner") && (
                                        <CardFooter>
                                          <Button
                                            color="warning"
                                            type="button"
                                            outline
                                            className="waves-effect waves-light mr-3"
                                            onClick={() => {
                                              setHeroSlide({
                                                ...heroSlide,
                                                bannerType: "BannerTwo",
                                                subtitle:
                                                  banners.bannerTwo.right
                                                    .subtitle,
                                                type: "right",
                                              });
                                              setHeroModal(true);
                                            }}
                                          >
                                            <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                            Edit
                                          </Button>
                                        </CardFooter>
                                      )}
                                    </Card>
                                  </Col>
                                ) : (
                                  <>
                                    {verifyConfig("isDynamicBanner") && (
                                      <Col
                                        lg="6"
                                        className="mt-3"
                                        style={{ textAlign: "center" }}
                                      >
                                        <Button
                                          color="primary"
                                          type="button"
                                          className="waves-effect waves-light mr-1"
                                          onClick={() => {
                                            setHeroSlide({
                                              ...heroSlide,
                                              bannerType: "BannerTwo",

                                              type: "right",
                                            });
                                            setHeroModal(true);
                                          }}
                                        >
                                          <i className="ri-add-fill align-middle mr-1"></i>{" "}
                                          Right Banner
                                        </Button>
                                      </Col>
                                    )}
                                  </>
                                )}
                              </Row>
                            </>
                          )}
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </Col>
                <Col lg={1}></Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Banner;
