import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Alert,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  CardText,
} from "reactstrap";
import { getToken } from "../../utils/user";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AvForm, AvField } from "availity-reactstrap-validation";

import axios from "axios";
import Paginator from "./Paginator";
import moment from "moment";

const ViewOrders = () => {
  let token = getToken();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 5,
    totalEntries: 0,
    totalPages: 0,
  });

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [data, setData] = useState({
    orderId: "",
    status: "",
  });
  const { status } = data;

  const getOrders = async (tab, current_page = 1, page_limit = 5) => {
    setLoading(true);
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        `/order/all?page=${current_page}&limit=${page_limit}&status=${
          tab === "0" ? "pending" : "completed"
        }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      setPagination({
        currentPage: resp.data.orders[0].metadata.page,
        pageLimit: resp.data.orders[0].metadata.limit,
        totalPages: resp.data.orders[0].metadata.total_pages,
        totalEntries: resp.data.orders[0].metadata.total_results,
      });
      setOrders(resp.data.orders[0].data);
      setLoading(false);
    }
  };

  const handlePagination = (page) => {
    getOrders(activeTab, page);
  };

  const toggle1 = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const getOrderTable = () => (
    <>
      <div className="table-responsive">
        <Table striped className=" mb-0">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Customer Details</th>
              <th>Payment Details</th>
              <th>Items</th>
              <th>Order Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <Spinner color="primary" />
                <th></th>
                <th></th>
                <th></th>
              </tr>
            ) : orders && orders.length === 0 ? (
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <h5>No Order found!</h5>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            ) : (
              orders.map((u) => (
                <tr>
                  <td>
                    <>
                      {u._id}
                      <br />
                      {moment(new Date(u.createdAt)).format("DD-MM-YYYY")}
                    </>
                  </td>
                  <td>
                    UserId: {u.user}
                    <br />
                    {u.address.firstName} {u.address.lastName}
                    <br />
                    {u.address.al1} {u.address.al2}
                    <br />
                    {u.address.city} {u.address.pinCode}
                    <br />
                    {u.address.state} {u.address.country}
                    <br />
                  </td>
                  <td>
                    ₹ {u.totalAmount}
                    <br />
                    TransactionID: {u.transaction_id}
                    <br />
                    {u.coupon && (
                      <>
                        Coupon:{" "}
                        {u.coupon?.couponCode +
                          " (" +
                          u.coupon?.discount +
                          "%)"}
                      </>
                    )}
                  </td>

                  <td>
                    {u.items.map((i) => (
                      <>
                        ProductId:{i.product._id}
                        <br />
                        Name: {i.product.name}
                        <br />
                        Quantity: {i.quantity}
                        <br />
                        Price: {i.price}
                        <br />
                        {i.discount && (
                          <>
                            Discount: {i.discount + "%"}
                            <br />
                          </>
                        )}
                        {i.selectedProductSize && (
                          <>
                            Size: {i.selectedProductSize}
                            <br />
                          </>
                        )}
                        <span>
                          <div
                            style={{
                              backgroundColor: i.selectedProductColor,
                              borderRadius: "50%",
                              height: 30,
                              width: 30,
                            }}
                          />
                        </span>
                        <br />
                        <hr />
                      </>
                    ))}
                  </td>
                  <td>
                    {u.status}
                    <br />

                    <Button
                      color="info"
                      type="button"
                      outline
                      className="waves-effect waves-light mt-2"
                      onClick={() => {
                        setData({ ...data, status: u.status, orderId: u._id });
                        setEditModal(true);
                      }}
                    >
                      <i className="ri-pencil-fill align-middle mr-2"></i>Update
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </>
  );

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    getOrders("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={editModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setEditModal(false)}>
          Update Order Status
        </ModalHeader>
        <ModalBody>
          {editError && <Alert color="danger">{editError}</Alert>}

          <div className="p-2 mt-3">
            <AvForm
              onSubmit={async () => {
                setEditLoading(true);
                try {
                  let resp = await axios.put(
                    process.env.REACT_APP_BACKEND_URL + `/order/status`,
                    data,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  setEditLoading(false);
                  if (resp.data.message) {
                    setData({
                      orderId: "",
                      status: "",
                    });
                    getOrders(activeTab);
                    setEditError(false);
                    setEditModal(false);
                  }
                } catch (err) {
                  setEditLoading(false);
                  setEditError(err.response.data.error);
                }
              }}
              className="form-horizontal"
            >
              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-medal-line auti-custom-input-icon"></i>
                <Label htmlFor="roleName">Order Status</Label>
                <AvField
                  name="roleName"
                  type="select"
                  className="form-control"
                  id="roleName"
                  placeholder="Enter Order Status"
                  onChange={(e) => setData({ ...data, status: e.target.value })}
                >
                  <option>{status}</option>
                  <option value="Created">Created</option>
                  <option value="Processing">Processing</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                </AvField>
              </FormGroup>

              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {editLoading ? "Loading ..." : "Update"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="View Orders"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "View Orders", link: "/orders" },
            ]}
          />

          {/* <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>*/}
          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={12}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <Col lg={12}>
                          <Nav pills className="navtab-bg nav-justified">
                            <NavItem>
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                }}
                                className={activeTab === "0" && "active"}
                                onClick={async () => {
                                  await toggle1("0");
                                  await getOrders("0");
                                }}
                              >
                                Pending Orders
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                }}
                                className={activeTab === "1" && "active"}
                                onClick={async () => {
                                  await toggle1("1");
                                  await getOrders("1");
                                }}
                              >
                                Completed Orders
                              </NavLink>
                            </NavItem>
                          </Nav>

                          <TabContent activeTab={activeTab}>
                            <TabPane tabId="0" className="p-3">
                              <Row>
                                <Col sm="12">
                                  <CardText>{getOrderTable()}</CardText>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="1" className="p-3">
                              <Row>
                                <Col sm="12">
                                  <CardText>{getOrderTable()}</CardText>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md="5">
                          <p>
                            <strong>
                              {" "}
                              Showing{" "}
                              {(pagination.currentPage - 1) *
                                pagination.pageLimit +
                                1}{" "}
                              to{" "}
                              {Math.min(
                                pagination.currentPage * pagination.pageLimit,
                                pagination.totalEntries
                              )}{" "}
                              of {pagination.totalEntries} entries
                            </strong>
                          </p>
                        </Col>
                        {!loading && orders.length > 0 && (
                          <Col md="7">
                            <Paginator
                              totalPages={pagination.totalPages}
                              currentPage={pagination.currentPage}
                              changePage={handlePagination}
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewOrders;
