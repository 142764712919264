import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getRole, getPermission, verifyConfig } from "../utils/user";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isSuperAdmin,
  isPermitted,
  permit,
  hasConfig,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      if (isAuthProtected && isSuperAdmin) {
        let role = getRole();
        if (role !== "SuperAdmin") {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }

        if (hasConfig && !verifyConfig(hasConfig)) {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }

      if (isAuthProtected && isPermitted) {
        let role = getRole();
        let permissions = getPermission();
        if (role !== "SuperAdmin") {
          if (permissions.filter((p) => p.name === permit).length === 0) {
            return (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            );
          }
        }
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
