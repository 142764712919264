/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Alert,
  Spinner,
  Form,
  Input,
} from "reactstrap";
import { getToken } from "../../utils/user";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AvForm, AvField } from "availity-reactstrap-validation";

import axios from "axios";

const Users = () => {
  let token = getToken();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    role: "",
  });
  const [roles, setRoles] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [filters, setFilters] = useState("firstName");
  const [filterRole, setFilterRole] = useState(false);
  const { _id, firstName, lastName, email, mobile, role } = user;

  const getUsers = async () => {
    setLoading(true);
    const resp = await axios.get(process.env.REACT_APP_BACKEND_URL + "/user", {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (resp.data.message) {
      setLoading(false);
      setUsers(resp.data.users);
    }
  };

  const getRoles = async (req, res) => {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/role/all",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (resp.data.message) {
      setRoles(resp.data.roles);
    }
  };

  const handleSubmit = async (event, errors, values) => {
    setEditLoading(true);
    try {
      let resp = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/user/update/${_id}`,
        values,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEditLoading(false);
      if (resp.data.message) {
        setEditError(false);
        setUser({
          _id: "",
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          role: "",
        });
        getUsers();
        setEditModal(false);
      }
    } catch (err) {
      setEditLoading(false);
      setEditError(err.response.data.error);
    }
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    getUsers();
    getRoles();
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={editModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setEditModal(false)}>
          Edit User Details
        </ModalHeader>
        <ModalBody>
          {editError && <Alert color="danger">{editError}</Alert>}

          <div className="p-2 mt-3">
            <AvForm
              onSubmit={handleSubmit}
              className="form-horizontal"
              model={user}
            >
              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-user-2-line auti-custom-input-icon"></i>
                <Label htmlFor="firstName">First Name</Label>
                <AvField
                  name="firstName"
                  value={firstName}
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter First Name"
                />
              </FormGroup>

              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-user-2-line auti-custom-input-icon"></i>
                <Label htmlFor="lastName">Last Name</Label>
                <AvField
                  name="lastName"
                  value={lastName}
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter Last Name"
                />
              </FormGroup>

              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-mail-line auti-custom-input-icon"></i>
                <Label htmlFor="email">Email</Label>
                <AvField
                  name="email"
                  value={email}
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter Email"
                />
              </FormGroup>

              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-phone-line auti-custom-input-icon"></i>
                <Label htmlFor="mobile">Mobile</Label>
                <AvField
                  name="mobile"
                  value={mobile}
                  type="text"
                  className="form-control"
                  id="mobile"
                  placeholder="Enter Mobile"
                />
              </FormGroup>
              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-medal-line auti-custom-input-icon"></i>
                <Label htmlFor="role">Role</Label>
                <AvField
                  name="role"
                  value={role}
                  type="select"
                  className="form-control"
                  id="role"
                  placeholder="Enter Role"
                >
                  <option value={role._id}>{role.roleName}</option>
                  {roles &&
                    roles
                      .filter((r) => r.roleName !== "SuperAdmin")
                      .map((v) => {
                        return <option value={v._id}>{v.roleName}</option>;
                      })}
                </AvField>
              </FormGroup>

              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {editLoading ? "Loading ..." : "Edit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={deleteModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setDeleteModal(false)}>
          Delete User
        </ModalHeader>
        <ModalBody>
          <div className="p-2 mt-3">
            <AvForm
              onValidSubmit={async () => {
                setDeleteLoading(true);
                try {
                  let resp = await axios.delete(
                    process.env.REACT_APP_BACKEND_URL + `/user/delete/${_id}`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  setDeleteLoading(false);
                  if (resp.data.message) {
                    setUser({
                      _id: "",
                      firstName: "",
                      lastName: "",
                      email: "",
                      mobile: "",
                      role: "",
                    });
                    getUsers();
                    setDeleteModal(false);
                  }
                } catch (err) {
                  setDeleteLoading(false);
                  setDeleteModal(false);
                }
              }}
              className="form-horizontal"
            >
              <h4>Are you sure you want to delete this user?</h4>

              <div className="text-center">
                <Button
                  color="danger"
                  className="mt-1 w-md waves-effect waves-light mr-2"
                  type="submit"
                >
                  {deleteLoading ? "Loading ..." : "Delete"}
                </Button>
                <Button
                  color="dark"
                  className="mt-1 w-md waves-effect waves-light"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="View Users"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "View Users", link: "/users" },
            ]}
          />

          {/* <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>*/}
          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={12}>
                  <Row>
                    <Col xl={4} md={12}>
                      <Form className="app-search">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            style={{ width: "100%", paddingLeft: "40%" }}
                            onChange={async (e) => {
                              setLoading(true);
                              setKeyword(e.target.value);
                              let resp = await axios.post(
                                process.env.REACT_APP_BACKEND_URL +
                                  `/user/filter`,
                                {
                                  filters,
                                  keyword: e.target.value,
                                  filterRole,
                                },
                                {
                                  headers: { Authorization: `Bearer ${token}` },
                                }
                              );
                              if (resp.data.message) {
                                setLoading(false);
                                setUsers(resp.data.users);
                              }
                            }}
                          />
                          <span>
                            <Input
                              type="select"
                              onChange={(e) => setFilters(e.target.value)}
                            >
                              <option value="firstName">FirstName</option>
                              <option value="lastName">LastName</option>
                              <option value="email">Email</option>
                              <option value="mobile">Mobile</option>
                            </Input>
                          </span>
                        </div>
                      </Form>
                    </Col>

                    <Col xl={4} md={12}>
                      <Form className="app-search">
                        <div className="position-relative">
                          <Input
                            type="select"
                            onChange={async (e) => {
                              setLoading(true);
                              setFilterRole(e.target.value);
                              let resp = await axios.post(
                                process.env.REACT_APP_BACKEND_URL +
                                  `/user/filter`,
                                {
                                  filters,
                                  keyword,
                                  filterRole: e.target.value,
                                },
                                {
                                  headers: { Authorization: `Bearer ${token}` },
                                }
                              );
                              if (resp.data.message) {
                                setLoading(false);
                                setUsers(resp.data.users);
                              }
                            }}
                          >
                            <option value="">Select Role</option>
                            {roles &&
                              roles
                                .filter((r) => r.roleName !== "SuperAdmin")
                                .map((f) => {
                                  return (
                                    <option value={f._id}>{f.roleName}</option>
                                  );
                                })}
                          </Input>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Col>

                <Col lg={12}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <div className="table-responsive">
                          <Table striped className=" mb-0">
                            <thead>
                              <tr>
                                <th>Sr no.</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Mobile No</th>
                                <th>Role</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th>
                                    <Spinner color="primary" />
                                  </th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              ) : users &&
                                users.filter(
                                  (a) => a.role.roleName !== "SuperAdmin"
                                ).length === 0 ? (
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th>
                                    <h5>No user found!</h5>
                                  </th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              ) : (
                                users &&
                                users
                                  .filter(
                                    (a) => a.role.roleName !== "SuperAdmin"
                                  )
                                  .map((u, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{u.firstName}</td>
                                      <td>{u.lastName}</td>
                                      <td>{u.email}</td>
                                      <td>{u.mobile}</td>
                                      <td>{u.role.roleName}</td>

                                      <td>
                                        <Button
                                          color="warning"
                                          type="button"
                                          outline
                                          className="waves-effect waves-light mr-1"
                                          onClick={() => {
                                            setUser(u);
                                            setEditModal(true);
                                          }}
                                        >
                                          <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                          Edit
                                        </Button>
                                      </td>
                                      <td>
                                        <Button
                                          color="danger"
                                          type="button"
                                          outline
                                          className="waves-effect waves-light mr-1"
                                          onClick={() => {
                                            setUser(u);
                                            setDeleteModal(true);
                                          }}
                                        >
                                          <i className="ri-delete-bin-fill align-middle mr-2"></i>{" "}
                                          Delete
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
