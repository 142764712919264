/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import classnames from "classnames";
import { getToken } from "../../utils/user";
import axios from "axios";
//Dropzone
import FormUpload from "./FormUpload";

//select
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const AddProduct = () => {
  let token = getToken();
  const [activeTab, setActiveTab] = useState(1);
  const [categories, setCategories] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [basicInfo, setBasicInfo] = useState({
    sku: "",
    name: "",
    description: "",
    productStock: "",
    discount: "",
    offerEnd: "",
    productPrice: null,
    productmrp: null,
    rating: "",
  });
  const [variationInfo, setVariationInfo] = useState([
    {
      color: "",
      price: "",
      mrp: "",
      stock: "",
      XS: "",
      S: "",
      M: "",
      L: "",
      XL: "",
      XXL: "",
      images: [],
    },
  ]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const limit = 2;
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const history = useHistory();

  const [variationChange, setVariationChange] = useState(false);

  const [detailsForm, setDetailsForm] = useState("");

  const getCategories = async () => {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/category/fetch",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      setCategories(resp.data.categories);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        setActiveTab(tab);
        if (tab !== 1) {
          setDisplay(false);
        }
      }
    }
  };

  useEffect(() => {
    getCategories();
    setDetailsForm(new FormData());
  }, []);

  const handleVariationChange = (key, i, value) => {
    let obj = variationInfo[i];
    let arr = variationInfo;
    obj = { ...obj, [key]: value };
    arr[i] = obj;
    setVariationInfo(arr);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (basicInfo.sku === "") {
      setSeverity("danger");
      setMessage("SKU is required field");
      setOpen(true);
      setLoading(false);
      window.scroll(0, 0);

      return;
    }
    if (basicInfo.name === "") {
      setSeverity("danger");
      setMessage("Product Name is required field");
      setOpen(true);
      setLoading(false);
      window.scroll(0, 0);

      return;
    }

    if (basicInfo.description === "") {
      setSeverity("danger");
      setMessage("Description is required field");
      setOpen(true);
      setLoading(false);
      window.scroll(0, 0);

      return;
    }

    if (!selectedCategories || selectedCategories.length === 0) {
      setSeverity("danger");
      setMessage("Categories is required field");
      setOpen(true);
      setLoading(false);
      window.scroll(0, 0);

      return;
    }

    if (
      (basicInfo.offerEnd === "" && basicInfo.discount) ||
      (basicInfo.discount === "" && basicInfo.offerEnd)
    ) {
      setSeverity("danger");
      setMessage("Offer end is required for discount");
      setOpen(true);
      setLoading(false);
      window.scroll(0, 0);

      return;
    }

    if (!variationInfo && variationInfo.length === 0) {
      setSeverity("danger");
      setMessage("Add atleast one variation");
      setOpen(true);
      setLoading(false);
      window.scroll(0, 0);
      return;
    }
    let priceArray = variationInfo.filter((v) => v.price == "");
    if (!basicInfo.productPrice && priceArray.length > 0) {
      setSeverity("danger");
      setMessage("Add variation price");
      setOpen(true);
      setLoading(false);
      window.scroll(0, 0);
      return;
    }

    priceArray = variationInfo.filter((v) => v.mrp == "");
    if (!basicInfo.productmrp && priceArray.length > 0) {
      setSeverity("danger");
      setMessage("Add variation mrp");
      setOpen(true);
      setLoading(false);
      window.scroll(0, 0);
      return;
    }

    priceArray = variationInfo.filter((v) => v.images.length === 0);
    if (!basicInfo.productmrp && priceArray.length > 0) {
      setSeverity("danger");
      setMessage("Add variation images");
      setOpen(true);
      setLoading(false);
      window.scroll(0, 0);
      return;
    }

    detailsForm.set("new", isNew);
    detailsForm.set("productPrice", null);
    detailsForm.set("productmrp", null);

    if (selectedCategories) {
      selectedCategories.map((p, index) =>
        detailsForm.set(`category[${index}]`, p.value)
      );
    }

    let resp = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/product/basicDetails",
      detailsForm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data.message) {
      variationInfo.map(async (data) => {
        let formData = new FormData();
        formData.set("L", data.L);
        formData.set("XL", data.XL);
        formData.set("XXL", data.XXL);
        formData.set("S", data.S);
        formData.set("M", data.M);
        formData.set("XS", data.XS);
        formData.set("color", data.color);
        formData.set("stock", data.stock);
        formData.set("price", data.price);
        formData.set("mrp", data.mrp);
        for (let key of Object.keys(data.images)) {
          formData.append("image", data.images[key]);
        }
        let response = await axios.put(
          process.env.REACT_APP_BACKEND_URL +
            `/product/variationDetails/${resp.data.product._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.message) {
          console.log("after variation");
          return;
        }
      });
      setLoading(false);
      window.scroll(0, 0);
      setMessage("Product created successfully");
      setOpen(true);
      setSeverity("success");

      setTimeout(() => {
        history.push({ pathname: `/products` });
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Add Product"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Add Product", link: "/product" },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    id="addproduct-nav-pills-wizard"
                    className="twitter-bs-wizard"
                  >
                    <Nav pills justified className="twitter-bs-wizard-nav">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 1 })}
                        >
                          <span className="step-number">01</span>
                          <span className="step-title">Basic Info</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                        >
                          <span className="step-number">02</span>
                          <span className="step-title">Variation Info</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={activeTab}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <TabPane tabId={1}>
                        <h4 className="card-title">Basic Information</h4>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>

                        <AvForm>
                          <Row>
                            <Col lg={6}>
                              <FormGroup>
                                <Label htmlFor="name">SKU</Label>
                                <Input
                                  id="sku"
                                  name="sku"
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    detailsForm.set("sku", e.target.value);
                                    setBasicInfo({
                                      ...basicInfo,
                                      sku: e.target.value,
                                    });
                                  }}
                                  required
                                />
                              </FormGroup>
                            </Col>

                            <Col lg={6}>
                              <FormGroup>
                                <Label htmlFor="name">Product Name</Label>
                                <Input
                                  id="name"
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    detailsForm.set("name", e.target.value);
                                    setBasicInfo({
                                      ...basicInfo,
                                      name: e.target.value,
                                    });
                                  }}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Label htmlFor="description">Description</Label>
                                <Input
                                  id="description"
                                  name="description"
                                  type="textarea"
                                  rows={3}
                                  className="form-control"
                                  onChange={(e) => {
                                    detailsForm.set(
                                      "description",
                                      e.target.value
                                    );
                                    setBasicInfo({
                                      ...basicInfo,
                                      description: e.target.value,
                                    });
                                  }}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label className="control-label">
                                  Categories (Select Multiple)
                                </Label>
                                <Select
                                  className="select2 select2-multiple"
                                  value={selectedCategories}
                                  isMulti
                                  options={
                                    categories &&
                                    categories.map((c) => {
                                      return { value: c.name, label: c.name };
                                    })
                                  }
                                  onChange={(v) => {
                                    setSelectedCategories(v);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={6}>
                              <FormGroup>
                                <Label htmlFor="rating">Rating</Label>
                                <Input
                                  id="rating"
                                  name="rating"
                                  type="number"
                                  className="form-control"
                                  onChange={(e) => {
                                    detailsForm.set("rating", e.target.value);
                                    setBasicInfo({
                                      ...basicInfo,
                                      rating: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg={4}>
                              <FormGroup>
                                <Label htmlFor="discount">Discount (%)</Label>
                                <Input
                                  id="discount"
                                  name="discount"
                                  type="number"
                                  className="form-control"
                                  onChange={(e) => {
                                    detailsForm.set("discount", e.target.value);
                                    setBasicInfo({
                                      ...basicInfo,
                                      discount: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={4}>
                              <FormGroup>
                                <Label htmlFor="offerEnd">Offer End</Label>
                                <Input
                                  id="offerEnd"
                                  name="offerEnd"
                                  type="date"
                                  className="form-control"
                                  onChange={(e) => {
                                    detailsForm.set("offerEnd", e.target.value);
                                    setBasicInfo({
                                      ...basicInfo,
                                      offerEnd: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label htmlFor="model">New</Label>
                                <div
                                  className="custom-control custom-switch mb-2"
                                  dir="ltr"
                                >
                                  <Input
                                    type="checkbox"
                                    className="custom-control-input p-3"
                                    id="customSwitch1"
                                    defaultChecked={isNew}
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor="customSwitch1"
                                    onClick={(e) => setIsNew(!isNew)}
                                  >
                                    Toggle the switch to change new arrival
                                    setting
                                  </Label>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </AvForm>
                      </TabPane>
                      {open && <Alert color={severity}>{message}</Alert>}

                      {/* ------------------------- PANE 2--------------------------------- */}

                      <TabPane tabId={2}>
                        <h4 className="card-title">Variation Information</h4>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>

                        {variationInfo.map((data, i) => (
                          <AvForm>
                            <Row>
                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="color">Color {i + 1}</Label>
                                  <Input
                                    id={"color" + i}
                                    name={"color" + i}
                                    type="color"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "color",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="stock">Stock</Label>
                                  <Input
                                    id="stock"
                                    name="stock"
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "stock",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="price">Price</Label>
                                  <Input
                                    id="price"
                                    name="price"
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "price",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="mrp">
                                    Selling Price (MRP)
                                  </Label>
                                  <Input
                                    id="mrp"
                                    name="mrp"
                                    type="number"
                                    className="form-control"
                                    required
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "mrp",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="XS">XS</Label>
                                  <Input
                                    id="XS"
                                    name="XS"
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "XS",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="S">S</Label>
                                  <Input
                                    id="S"
                                    name="S"
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "S",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="M">M</Label>
                                  <Input
                                    id="M"
                                    name="M"
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "M",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="L">L</Label>
                                  <Input
                                    id="L"
                                    name="L"
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "L",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="XL">XL</Label>
                                  <Input
                                    id="XL"
                                    name="XL"
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "XL",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg={3}>
                                <FormGroup>
                                  <Label htmlFor="XXL">XXL</Label>
                                  <Input
                                    id="XXL"
                                    name="XXL"
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleVariationChange(
                                        "XXL",
                                        i,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={12}>
                                <FormUpload
                                  label="Product Images"
                                  setVariationInfo={setVariationInfo}
                                  variationInfo={variationInfo}
                                  id={i}
                                />
                              </Col>
                            </Row>
                            {i !== 0 && (
                              <Row>
                                <Col lg={3}>
                                  <Button
                                    color="danger"
                                    outline
                                    type="button"
                                    className="mr-2 waves-effect waves-light"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      var array = variationInfo;
                                      array.splice(i, 1);
                                      setVariationInfo(array);
                                      setVariationChange(!variationChange);
                                    }}
                                  >
                                    Delete Color
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </AvForm>
                        ))}
                      </TabPane>
                    </TabContent>
                    {activeTab === 2 && (
                      <Col md={4}>
                        <div className="text-left mt-2">
                          <Button
                            color="primary"
                            outline
                            className="mr-2 waves-effect waves-light"
                            onClick={(e) => {
                              e.preventDefault();
                              let arr = variationInfo;
                              arr.push({
                                color: "",
                                price: "",
                                mrp: "",
                                stock: "",
                                XS: "",
                                S: "",
                                M: "",
                                L: "",
                                XL: "",
                                XXL: "",
                              });
                              setVariationInfo(arr);
                              setVariationChange(!variationChange);
                            }}
                          >
                            Add New Color
                          </Button>
                        </div>
                      </Col>
                    )}
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTab === 1 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab - 1);
                            setDisplay(true);
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      {activeTab !== limit ? (
                        <li
                          className={
                            activeTab === limit ? "next disabled" : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      ) : (
                        <li className={"next"}>
                          <Link
                            to="#"
                            onClick={handleSubmit}
                            disabled={loading}
                          >
                            {loading ? "Creating Product..." : "Create Product"}
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddProduct;
