import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
  CardHeader,
  Card,
  Collapse,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  Spinner,
  CardFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { getToken, verifyConfig } from "../../utils/user";
import FormUpload from "./FormUpload";

const Logo = (props) => {
  const history = useHistory();
  let token = getToken();
  const [loading, setLoading] = useState(false);
  const [logoModal, setLogoModal] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const [logoSlide, setLogoSlide] = useState({
    name: "",
  });
  const [logoImage, setLogoImage] = useState(null);

  const [logo, setLogo] = useState(null);

  const fetchLogo = async () => {
    setLoading(true);
    const resp = await axios.get(process.env.REACT_APP_BACKEND_URL + "/logo", {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (resp.data.message) {
      setLogo(resp.data.logo);

      setLoading(false);
    }
  };

  const handleLogoSubmit = async () => {
    setLogoLoading(true);
    if (!logoSlide.name || logoSlide.name === "") {
      setLogoLoading(false);
      setLogoError("Name is required field");
      return;
    }

    let form = new FormData();
    form.set("name", logoSlide.name);
    if (logoImage) form.set("image", logoImage);

    let resp = await axios.put(
      process.env.REACT_APP_BACKEND_URL + "/logo",
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data.message) {
      setLogoLoading(false);
      setLogoError(false);
      setLogoSlide({
        name: "",
      });
      setLogoImage(null);
      setLogoModal(false);
      setTimeout(() => {
        window.location.replace("/logo");
      }, 3000);
    }
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    fetchLogo();
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={logoModal} centered={true} scrollable={true} size="xl">
        <ModalHeader toggle={() => setLogoModal(false)}>Add Slide</ModalHeader>
        <ModalBody>
          {logoError && <Alert color="danger">{logoError}</Alert>}

          <div className="p-2 mt-3">
            <AvForm
              onSubmit={() => handleLogoSubmit()}
              className="form-horizontal"
            >
              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-text auti-custom-input-icon"></i>
                <Label htmlFor="name">Name</Label>
                <AvField
                  name="name"
                  value={logoSlide.name}
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter App Name"
                  onChange={(e) =>
                    setLogoSlide({ ...logoSlide, name: e.target.value })
                  }
                />
              </FormGroup>

              <FormUpload setImage={setLogoImage} />
              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {logoLoading ? "Loading ..." : "Add"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Update Logo"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Update Logo", link: "/logo" },
            ]}
          />

          {/* <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>*/}
          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={1}></Col>
                <Col lg={10}>
                  <Card className="mb-1">
                    <CardHeader id="headingOne">
                      <h6 className="m-0 font-14">App Name & Logo</h6>
                    </CardHeader>

                    <CardBody>
                      {loading ? (
                        <Row>
                          <Col style={{ textAlign: "center" }}>
                            <Spinner color="primary" />
                          </Col>
                        </Row>
                      ) : (
                        <>
                          {verifyConfig("isDynamicLogo") && !logo && (
                            <Row>
                              <Col className="d-flex justify-content-end align-items-center">
                                <Button
                                  color="primary"
                                  type="button"
                                  className="waves-effect waves-light mr-1"
                                  onClick={() => {
                                    setLogoModal(true);
                                  }}
                                >
                                  <i className="ri-add-fill align-middle mr-1"></i>{" "}
                                  Add
                                </Button>
                              </Col>
                            </Row>
                          )}
                          {logo && (
                            <Row>
                              <Col lg="4"></Col>
                              <Col lg="4" className="mt-3">
                                <Card>
                                  <img alt="logo image" src={logo.image} />
                                  <CardBody>
                                    <CardTitle tag="h5">{logo.name}</CardTitle>
                                  </CardBody>
                                  {verifyConfig("isDynamicLogo") && (
                                    <CardFooter>
                                      <Button
                                        color="warning"
                                        type="button"
                                        outline
                                        className="waves-effect waves-light mr-3"
                                        onClick={() => {
                                          setLogoSlide({
                                            ...logoSlide,
                                            name: logo.name,
                                          });
                                          setLogoModal(true);
                                        }}
                                      >
                                        <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                        Edit
                                      </Button>
                                    </CardFooter>
                                  )}
                                </Card>
                              </Col>
                            </Row>
                          )}
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={1}></Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Logo;
