/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { getToken, getRole } from "../../utils/user";
import axios from "axios";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";

const Dashboard = () => {
  let token = getToken();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [reports, setReports] = useState([]);
  const [monthlySales, setMonthlySales] = useState([]);
  const [pastSales, setPastSales] = useState([]);

  const getData = async () => {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/order/sales",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      setReports([
        {
          icon: "ri-user-line",
          title: "Total Customers",
          value: resp.data.data.customers,
        },
        {
          icon: "ri-store-2-line",
          title: "Number of Orders",
          value: resp.data.data.orders,
        },
        {
          icon: "ri-briefcase-4-line",
          title: "Total Sales",
          value: `₹ ${resp.data.data.totalSales?.toFixed(2)}`,
        },
      ]);
      setMonthlySales(resp.data.data.monthlySales);
      setPastSales(resp.data.data.pastSales);
    }
  };

  useEffect(() => {
    getRole() === "SuperAdmin" && getData();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItems={[{ title: "Dashboard", link: "#" }]}
          />
          {getRole() === "SuperAdmin" ? (
            <Row>
              <Col xl={12}>
                <Row>
                  <MiniWidgets reports={reports} />
                </Row>

                {/* revenue Analytics */}
                <RevenueAnalytics
                  monthlySales={monthlySales}
                  pastSales={pastSales}
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="justify-content-center">
                <Col lg={5}>
                  <div className="text-center mb-5">
                    <h4>Dashboard</h4>
                    <p className="text-muted mb-4">
                      Welcome to Jewells's dashboard. View your profile
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={4}></Col>
                <Col lg={4}>
                  <Card className="pricing-box">
                    <CardBody className="p-4">
                      <div className="text-center">
                        <div className="mt-3">
                          <i className="ri-user-line text-primary h1"></i>
                        </div>
                        <h5 className="mt-4">Profile</h5>

                        <div className="font-size-16 mt-4 pt-2">
                          <ul className="list-unstyled plan-features">
                            <li>
                              Name: {user.user.firstName} {user.user.lastName}
                            </li>
                            <li>Mobile: {user.user.mobile}</li>
                            <li>Role: {user.user.role}</li>
                            <li>Franchise: {user.user.franchise}</li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}></Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
