import React from "react";
import { Card, CardBody } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

const RevenueAnalytics = (props) => {
  let state = {
    series: [
      {
        name: new Date().getFullYear(),
        type: "column",
        data: props.monthlySales,
      },
      {
        name: new Date().getFullYear() - 1,
        type: "line",
        data: props.pastSales,
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 3],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },

      legend: {
        show: false,
      },
      colors: ["#5664d2", "#1cbb8c"],
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Sales Analytics</h4>
          <div>
            <div id="line-column-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="line"
                height="280"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RevenueAnalytics;
