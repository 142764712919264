import { AvField, AvForm } from "availity-reactstrap-validation";
import Axios from "axios";
import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

const ResetPassword = (props) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    password: "",
    confirmPassword: "",
  });

  const { password, confirmPassword } = user;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);
    let response = await Axios.post(
      process.env.REACT_APP_BACKEND_URL +
        `/user/resetPassword/${props.match.params.token}`,
      {
        newPassword: password,
        confirmNewPassword: confirmPassword,
      }
    );
    if (response.data.message) {
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        history.push("/login");
      }, 3000);
      setError(false);
      setUser({
        password: "",
        confirmPassword: "",
      });
    } else {
      setLoading(false);
      setError(response.data.error);
    }
  };
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/">
          <i className="mdi mdi-home-variant h2 text-white"></i>
        </Link>
      </div>

      <div>
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/misc/jewells.png"
                                }
                                height="50"
                                alt="logo"
                              />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">Reset Password</h4>
                          <p className="text-muted">Reset Password</p>
                        </div>

                        {error && error ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}
                        {success && (
                          <Alert color="success">
                            Password reset successfully
                          </Alert>
                        )}

                        <div className="p-2 mt-5">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleSubmit}
                          >
                            <FormGroup className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">New Password</Label>
                              <AvField
                                name="password"
                                value={password}
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                onChange={(e) =>
                                  setUser({ ...user, password: e.target.value })
                                }
                              />
                            </FormGroup>
                            <FormGroup className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">
                                Confirm Password
                              </Label>
                              <AvField
                                name="confirmPassword"
                                value={confirmPassword}
                                type="password"
                                className="form-control"
                                id="userConfirmpassword"
                                placeholder="Enter password"
                                onChange={(e) =>
                                  setUser({
                                    ...user,
                                    confirmPassword: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>

                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                {loading ? "Loading..." : "Submit"}
                              </Button>
                            </div>
                          </AvForm>
                        </div>

                        <div className="mt-5 text-center">
                          {/*}<p>Don't have an account ? <Link to="/register" className="font-weight-medium text-primary"> Register </Link> </p>*/}
                          <p>
                            © {new Date().getFullYear()} Template. Crafted by{" "}
                            <a href="https://thehelioweb.com/" target="blank">
                              HelioWeb
                            </a>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
