/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { getToken } from "../../utils/user";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FormUpload from "./FormUpload";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";

import { useHistory } from "react-router-dom";

const Theme = () => {
  const history = useHistory();
  let token = getToken();

  const [theme, setTheme] = useState({
    primary: "",
    secondary: "",
    header: "",
    tertiary: "",
    quaternary: "",
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [themeLoading, setThemeLoading] = useState(false);

  const { primary, secondary, header, tertiary, quaternary } = theme;

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    try {
      let resp = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/theme",
        theme
      );
      setLoading(false);
      if (resp.data.message) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          getTheme();
        }, 3000);
        setError(false);
      }
    } catch (err) {
      setLoading(false);
      setSuccess(false);
      setError(err.response.data.error);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const getTheme = async () => {
    setThemeLoading(true);
    const resp = await axios.get(process.env.REACT_APP_BACKEND_URL + "/theme");
    if (resp.data.message && resp.data.theme) {
      setTheme(resp.data.theme);
    }
    setThemeLoading(false);
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    getTheme();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Update Theme"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Update Theme", link: "/theme" },
            ]}
          />

          {/* <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>*/}
          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={2}></Col>
                <Col lg={8}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <Col lg={9}>
                          <div>
                            <div className="text-center">
                              {/*  <div>
                                                    <Link to="#" className="logo"><img src={logodark} height="20" alt="logo"/></Link>
                                                </div>*/}

                              <h4 className="font-size-18 mt-4">
                                Update Theme
                              </h4>
                            </div>

                            {success && (
                              <Alert color="success">
                                Theme created Successfully.
                              </Alert>
                            )}

                            {error && <Alert color="danger">{error}</Alert>}

                            <div className="p-2 mt-5">
                              {!themeLoading && (
                                <AvForm
                                  onValidSubmit={handleSubmit}
                                  className="form-horizontal"
                                >
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className=" ri-number-1 auti-custom-input-icon"></i>
                                    <Label htmlFor="name">Primary Color</Label>
                                    <AvField
                                      name="primary"
                                      value={primary}
                                      type="color"
                                      className="form-control"
                                      id="primary"
                                      placeholder="Enter Primary Color"
                                      onChange={(e) => {
                                        setTheme({
                                          ...theme,
                                          primary: e.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-number-2 auti-custom-input-icon"></i>
                                    <Label htmlFor="name">
                                      Secondary Color
                                    </Label>
                                    <AvField
                                      name="secondary"
                                      value={secondary}
                                      type="color"
                                      className="form-control"
                                      id="secondary"
                                      placeholder="Enter Secondary Color"
                                      onChange={(e) => {
                                        setTheme({
                                          ...theme,
                                          secondary: e.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className=" ri-number-3 auti-custom-input-icon"></i>
                                    <Label htmlFor="name">Tertiary Color</Label>
                                    <AvField
                                      name="tertiary"
                                      value={tertiary}
                                      type="color"
                                      className="form-control"
                                      id="tertiary"
                                      placeholder="Enter Tertiary Color"
                                      onChange={(e) => {
                                        setTheme({
                                          ...theme,
                                          tertiary: e.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className=" ri-number-4 auti-custom-input-icon"></i>
                                    <Label htmlFor="name">
                                      Quaternary Color
                                    </Label>
                                    <AvField
                                      name="quaternary"
                                      value={quaternary}
                                      type="color"
                                      className="form-control"
                                      id="quaternary"
                                      placeholder="Enter Quaternary Color"
                                      onChange={(e) => {
                                        setTheme({
                                          ...theme,
                                          quaternary: e.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className=" ri-heading auti-custom-input-icon"></i>
                                    <Label htmlFor="name">Header Color</Label>
                                    <AvField
                                      name="header"
                                      value={header}
                                      type="color"
                                      className="form-control"
                                      id="header"
                                      placeholder="Enter Header Color"
                                      onChange={(e) => {
                                        setTheme({
                                          ...theme,
                                          header: e.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>

                                  <div className="text-center">
                                    <Button
                                      color="primary"
                                      className="mt-1 w-md waves-effect waves-light"
                                      type="submit"
                                    >
                                      {loading ? "Loading ..." : "Update"}
                                    </Button>
                                  </div>

                                  {/*<div className="mt-4 text-center">
                                                        <p className="mb-0">By registering you agree to the Template <Link to="#" className="text-primary">Terms of Use</Link></p>
                                                    </div>*/}
                                </AvForm>
                              )}
                            </div>

                            {/*<div className="mt-5 text-center">
                                                <p>Already have an account ? <Link to="/login" className="font-weight-medium text-primary"> Login</Link> </p>
                                                <p>© 2020 Template. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                            </div>*/}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={2}></Col>
                {/*<Col lg={7}>
                        <div className="authentication-bg">
                            <div className="bg-overlay"></div>
                        </div>
                    </Col>*/}
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Theme;
