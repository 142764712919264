import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { getToken } from "../../utils/user";

const Coupon = (props) => {
  const history = useHistory();
  let token = getToken();
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState({
    couponCode: Math.random().toString(36).slice(2, 8),
    expiredDate: new Date(),
    discount: 0,
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { couponCode, expiredDate, discount } = coupon;

  const handleSubmit = async (event) => {
    setError(false);
    setLoading(true);
    event.preventDefault();
    let resp = await axios.post(
      process.env.REACT_APP_BACKEND_URL + `/coupon`,
      coupon,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.error) {
      setError(resp.data.error);
      setLoading(false);
      return;
    }
    if (resp.data.message) {
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setError(false);
        setSuccess(false);
        history.push("/coupons");
      }, 3000);
    }
  };

  useEffect(() => {
    setError(false);
    setSuccess(false);
    document.body.classList.add("auth-body-bg");
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Coupon"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Coupon", link: "/coupon" },
            ]}
          />

          {/* <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>*/}
          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={3}></Col>
                <Col lg={6}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <Col lg={9}>
                          <div>
                            <div className="text-center">
                              {/*  <div>
                                                    <Link to="#" className="logo"><img src={logodark} height="20" alt="logo"/></Link>
                                                </div>*/}

                              <h4 className="font-size-18 mt-4">Coupon</h4>
                              <p className="text-muted">Create coupon now.</p>
                            </div>

                            {success && (
                              <Alert color="success" isOpen={success}>
                                Coupon created successfully!
                              </Alert>
                            )}

                            {error && (
                              <Alert color="danger" isOpen={error}>
                                {error}
                              </Alert>
                            )}

                            <div className="p-2 mt-5">
                              <AvForm
                                onValidSubmit={handleSubmit}
                                className="form-horizontal"
                              >
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-price-tag-3-line auti-custom-input-icon"></i>
                                  <Label htmlFor="couponCode">
                                    Coupon Code
                                  </Label>
                                  <AvField
                                    name="couponCode"
                                    value={couponCode}
                                    type="text"
                                    className="form-control"
                                    id="couponCode"
                                    placeholder="Enter Coupon Code"
                                    onChange={(e) =>
                                      setCoupon({
                                        ...coupon,
                                        couponCode: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-calendar-event-line auti-custom-input-icon"></i>
                                  <Label htmlFor="expiredDate">
                                    Expired Date
                                  </Label>
                                  <AvField
                                    name="expiredDate"
                                    value={moment(new Date(expiredDate)).format(
                                      "YYYY-MM-DD"
                                    )}
                                    type="date"
                                    className="form-control"
                                    id="expiredDate"
                                    placeholder="Enter Expired Date"
                                    onChange={(e) =>
                                      setCoupon({
                                        ...coupon,
                                        expiredDate: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-percent-line auti-custom-input-icon"></i>
                                  <Label htmlFor="discount">Discount</Label>
                                  <AvField
                                    name="discount"
                                    value={props.coupon ? "" : discount}
                                    type="number"
                                    className="form-control"
                                    id="discount"
                                    placeholder="Enter Discount"
                                    onChange={(e) =>
                                      setCoupon({
                                        ...coupon,
                                        discount: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>

                                <div className="text-center">
                                  <Button
                                    color="primary"
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    {loading ? "Loading ..." : "Create"}
                                  </Button>
                                </div>
                              </AvForm>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={3}></Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Coupon;
