import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  CardImg,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Alert,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { getToken } from "../../utils/user";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FormUpload from "./FormUpload";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserImg from "../../assets/images/user.png";

const ViewTestimonial = () => {
  let token = getToken();
  const [testimonials, setTestimonials] = useState([]);
  const [testimonial, setTestimonial] = useState({
    _id: "",
    customerName: "",
    content: "",
  });
  const [image, setImage] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [form, setForm] = useState("");
  const { _id, customerName, content } = testimonial;

  const handleSubmit = async (id) => {
    setEditLoading(true);
    try {
      form.set("customerName", customerName);
      form.set("content", content);
      if (image) {
        form.set("image", image);
      }
      let resp = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/testimonial/${id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEditLoading(false);
      if (resp.data.message) {
        setEditError(false);
        setTestimonial({
          _id: "",
          customerName: "",
          content: "",
        });
        setForm(new FormData());
        getTestimonials();
        setEditModal(false);
      }
    } catch (err) {
      setEditLoading(false);
      setEditError(err.response.data.error);
    }
  };

  const getTestimonials = async () => {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/testimonial",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (resp.data.message) {
      setTestimonials(resp.data.testimonials);
    }
  };

  useEffect(() => {
    getTestimonials();
    setForm(new FormData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={editModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setEditModal(false)}>
          Edit Testimonial
        </ModalHeader>
        <ModalBody>
          {editError && <Alert color="danger">{editError}</Alert>}

          <div className="p-2 mt-3">
            <AvForm
              onValidSubmit={() => handleSubmit(_id)}
              className="form-horizontal"
            >
              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-file-list-line auti-custom-input-icon"></i>
                <Label htmlFor="customerName">Customer Name</Label>
                <AvField
                  name="customerName"
                  value={customerName}
                  type="text"
                  className="form-control"
                  id="customerName"
                  placeholder="Enter Customer Name"
                  onChange={(e) => {
                    setTestimonial({
                      ...testimonial,
                      customerName: e.target.value,
                    });
                  }}
                />
              </FormGroup>
              <FormGroup className="auth-form-group-custom mb-4">
                <i
                  className="ri-layout-6-line auti-custom-input-icon"
                  style={{ top: "25%" }}
                ></i>
                <Label htmlFor="content">Content</Label>
                <AvField
                  name="content"
                  value={content}
                  type="textarea"
                  className="form-control"
                  id="content"
                  placeholder="Enter Content"
                  onChange={(e) => {
                    setTestimonial({
                      ...testimonial,
                      content: e.target.value,
                    });
                  }}
                  style={{ height: 120 }}
                />
              </FormGroup>
              <FormUpload setImage={setImage} />

              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {editLoading ? "Loading ..." : "Edit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={deleteModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setDeleteModal(false)}>
          Delete Testimonial
        </ModalHeader>
        <ModalBody>
          <div className="p-2 mt-3">
            <AvForm
              onValidSubmit={async () => {
                setDeleteLoading(true);
                try {
                  let resp = await axios.delete(
                    process.env.REACT_APP_BACKEND_URL + `/testimonial/${_id}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                  );
                  setDeleteLoading(false);
                  if (resp.data.message) {
                    setTestimonial({
                      _id: "",
                      content: "",
                      customerName: "",
                    });
                    getTestimonials();
                    setDeleteModal(false);
                  }
                } catch (err) {
                  setDeleteLoading(false);
                  setDeleteModal(false);
                  setUpdateModal(true);
                }
              }}
              className="form-horizontal"
            >
              <h4>Are you sure you want to delete this testimonial?</h4>

              <div className="text-center">
                <Button
                  color="danger"
                  className="mt-1 w-md waves-effect waves-light mr-2"
                  type="submit"
                >
                  {deleteLoading ? "Loading ..." : "Delete"}
                </Button>
                <Button
                  color="dark"
                  className="mt-1 w-md waves-effect waves-light"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="View Testimonials"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "View Testimonials", link: "/testimonials" },
            ]}
          />

          <Row>
            {testimonials &&
              testimonials.map((c, key) => (
                <Col lg={12} key={key}>
                  <Card>
                    <CardBody>
                      <div>
                        <Row>
                          <Col lg={2}>
                            <img
                              src={c.image ? c.image : UserImg}
                              style={{ height: "150px", width: "150px" }}
                            />
                          </Col>
                          <Col lg={10} className="p-3">
                            <h5>{c.customerName}</h5>
                            <p>{c.content}</p>
                            <Button
                              color="warning"
                              type="button"
                              outline
                              className="waves-effect waves-light mr-3"
                              onClick={() => {
                                setTestimonial(c);
                                setEditModal(true);
                              }}
                            >
                              <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                              Edit
                            </Button>

                            <Button
                              color="danger"
                              type="button"
                              outline
                              className="waves-effect waves-light mr-1"
                              onClick={() => {
                                setTestimonial(c);
                                setDeleteModal(true);
                              }}
                            >
                              <i className="ri-delete-bin-fill align-middle mr-2"></i>{" "}
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewTestimonial;
