import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  CardImg,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { getToken } from "../../utils/user";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FormUpload from "./FormUpload";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ViewCategory = () => {
  let token = getToken();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({
    _id: "",
    description: "",
  });
  const [image, setImage] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [form, setForm] = useState("");
  const { _id, description } = category;
  const [subCategories, setSubCategories] = useState([]);
  const handleSubmit = async (id) => {
    setEditLoading(true);
    try {
      if (image) {
        form.set("image", image);
      }
      let resp = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/category/update/${id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEditLoading(false);
      if (resp.data.message) {
        setEditError(false);
        setCategory({
          _id: "",
          name: "",
          description: "",
        });
        setForm(new FormData());
        getCategories();
        setEditModal(false);
      }
    } catch (err) {
      setEditLoading(false);
      setEditError(err.response.data.error);
    }
  };

  const getCategories = async () => {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/category/all",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (resp.data.message) {
      setCategories(resp.data.categories);
    }
  };

  useEffect(() => {
    getCategories();
    setForm(new FormData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={viewModal} size="xl" centered={true} scrollable={true}>
        <ModalHeader toggle={() => setViewModal(false)}>
          View Sub Categories
        </ModalHeader>
        <ModalBody>
          <div className="p-2 mt-3">
            {subCategories ? (
              subCategories.length === 0 ? (
                <h5>No subCategories found!</h5>
              ) : (
                <>
                  <Card>
                    <CardBody>
                      <div>
                        <Row className="no-gutters">
                          {subCategories.map((p, i) => (
                            <Col xl={4} sm={6}>
                              <Card>
                                <CardBody>
                                  <div className="text-center">
                                    <CardImg
                                      src={p.image}
                                      alt=""
                                      className="mt-2 mb-4"
                                      width="378px"
                                      height="180px"
                                    />
                                    <Media body>
                                      <h5 className="text-truncate">
                                        <Link to="#" className="text-dark">
                                          {p.name}
                                        </Link>
                                      </h5>
                                    </Media>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </>
              )
            ) : null}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={editModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setEditModal(false)}>
          Edit Category
        </ModalHeader>
        <ModalBody>
          {editError && <Alert color="danger">{editError}</Alert>}

          <div className="p-2 mt-3">
            <AvForm
              onValidSubmit={() => handleSubmit(_id)}
              className="form-horizontal"
            >
              <FormGroup className="auth-form-group-custom mb-4">
                <i
                  className="ri-layout-6-line auti-custom-input-icon"
                  style={{ top: "25%" }}
                ></i>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  value={description}
                  type="textarea"
                  className="form-control"
                  id="description"
                  placeholder="Enter Description"
                  onChange={(e) => {
                    form.set("description", e.target.value);
                    setCategory({ ...category, description: e.target.value });
                  }}
                  style={{ height: 120 }}
                />
              </FormGroup>
              <FormUpload setImage={setImage} />

              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {editLoading ? "Loading ..." : "Edit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={updateModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setUpdateModal(false)}>
          Delete Category
        </ModalHeader>
        <ModalBody>
          <div className="p-2 mt-3">
            <h5>
              You cannot delete this category, since products are associated to
              it.
            </h5>
            <div className="text-center">
              <Button
                color="dark"
                className="mt-1 w-md waves-effect waves-light mr-2"
                onClick={() => setUpdateModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={deleteModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setDeleteModal(false)}>
          Delete Category
        </ModalHeader>
        <ModalBody>
          <div className="p-2 mt-3">
            <AvForm
              onValidSubmit={async () => {
                setDeleteLoading(true);
                try {
                  let resp = await axios.delete(
                    process.env.REACT_APP_BACKEND_URL +
                      `/category/delete/${_id}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                  );
                  setDeleteLoading(false);
                  if (resp.data.message) {
                    setCategory({
                      _id: "",
                      name: "",
                      description: "",
                    });
                    getCategories();
                    setDeleteModal(false);
                  }
                } catch (err) {
                  setDeleteLoading(false);
                  setDeleteModal(false);
                  setUpdateModal(true);
                }
              }}
              className="form-horizontal"
            >
              <h4>Are you sure you want to delete this category?</h4>

              <div className="text-center">
                <Button
                  color="danger"
                  className="mt-1 w-md waves-effect waves-light mr-2"
                  type="submit"
                >
                  {deleteLoading ? "Loading ..." : "Delete"}
                </Button>
                <Button
                  color="dark"
                  className="mt-1 w-md waves-effect waves-light"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="View Categories"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "View Categories", link: "/categories" },
            ]}
          />

          <Row>
            {categories &&
              categories.map((c, key) => (
                <Col xl={3} sm={6} key={key}>
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <CardImg
                          src={c.image}
                          alt=""
                          className="mt-2 mb-4"
                          width="378px"
                          height="180px"
                        />
                        <Media body>
                          <h5 className="text-truncate">
                            <Link to="#" className="text-dark">
                              {c.name}
                            </Link>
                          </h5>
                        </Media>
                      </div>

                      <hr className="my-4" />

                      <Row className="text-center">
                        {c.subCategories.length !== 0 && (
                          <Col>
                            <Button
                              color="info"
                              type="button"
                              outline
                              className="waves-effect waves-light mr-1"
                              onClick={() => {
                                setViewModal(true);
                                setSubCategories(c.subCategories);
                              }}
                            >
                              <i className="ri-eye-fill align-middle mr-2"></i>
                              SubCategories
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewCategory;
