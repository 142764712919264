import axios from "axios";
import {getToken} from "../utils/user";

export const login = async (url,data) =>{
  try {
    let resp = await axios.post(url,data);
    return resp.data;
  }catch(err){
    return err.response.data
  }
}

export const register= async(url, user)=>{
  let token=await getToken();
  try {
    let resp=await axios.post(url, user, { headers: {"Authorization" : `Bearer ${token}`} });
    return resp.data;
  }catch(err){
    return err.response.data
  }
}
