/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Input,
  Label,
  Spinner,
  Button,
  ButtonGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
// RangeSlider
import "nouislider/distribute/nouislider.css";
import axios from "axios";
import { getToken } from "../../utils/user";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Products = () => {
  const token = getToken();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colorList, setColorList] = useState([]);
  const [filter, setFilter] = useState({
    price: {
      upperLimit: "",
      lowerLimit: "",
    },
    size: [],
    color: [],
  });

  const getProducts = async () => {
    setLoading(true);
    let resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + `/product/all`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (resp.data.message) {
      setProducts(resp.data.products);
      resp.data.products.map((p) => {
        p.variation.length !== 0 &&
          p.variation.map((v) => {
            if (colorList.indexOf(v.color) === -1) {
              colorList.push(v.color);
            }
          });
      });
      setLoading(false);
    }
  };

  const onCheckboxBtnClick = async (array, selected) => {
    if (array === "price") {
      Promise.resolve()
        .then(() => {
          setFilter({ ...filter, price: selected });
        })
        .then(() => getFilteredProducts());
    } else {
      const index = filter[array].indexOf(selected);
      if (index < 0) {
        filter[array].push(selected);
      } else {
        filter[array].splice(index, 1);
      }

      setFilter({ ...filter });

      await getFilteredProducts();
    }
  };

  const getFilteredProducts = async () => {
    setLoading(true);
    const resp = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/product/filter",
      filter,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      setProducts(resp.data.products);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
    setFilter({
      price: {
        upperLimit: "",
        lowerLimit: "",
      },
      size: [],
      color: [],
    });
  }, []);

  useEffect(() => {
    getFilteredProducts();
  }, [filter.price.upperLimit]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="View Products"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "View Products", link: "/products" },
            ]}
          />

          <Row>
            <Col lg={3}>
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <h5 className="mb-0">Filters</h5>
                </CardHeader>

                <CardBody>
                  {colorList && (
                    <>
                      <h5 className="font-size-14 mb-2 mt-2">Color</h5>

                      <Row>
                        {colorList.map((c) => (
                          <Col md={2} sm={4}>
                            <ButtonGroup>
                              <Button
                                style={{
                                  backgroundColor: c,
                                  boxShadow: filter.color.includes(c)
                                    ? "2px 1px 2px 1px #808080"
                                    : "none",
                                }}
                                type="button"
                                className="waves-effect waves-light mb-2 p-3"
                                onClick={() => onCheckboxBtnClick("color", c)}
                              />
                            </ButtonGroup>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  <h5 className="font-size-14 mb-2 mt-2">Size</h5>

                  <Row>
                    <Col md={3} sm={4}>
                      <ButtonGroup>
                        <Button
                          color="light"
                          type="button"
                          outline
                          className="waves-effect waves-light mb-2 pl-4 pr-4"
                          onClick={() => onCheckboxBtnClick("size", "XS")}
                          active={filter.size.includes("XS")}
                        >
                          <span>
                            <span style={{ fontSize: 18 }}>XS</span>
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col md={3} sm={4}>
                      <ButtonGroup>
                        <Button
                          color="light"
                          type="button"
                          outline
                          className="waves-effect waves-light mb-2 pl-4 pr-4"
                          onClick={() => onCheckboxBtnClick("size", "S")}
                          active={filter.size.includes("S")}
                        >
                          <span>
                            <span style={{ fontSize: 18 }}>S</span>
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col md={3} sm={4}>
                      <ButtonGroup>
                        <Button
                          color="light"
                          type="button"
                          outline
                          className="waves-effect waves-light mb-2 pl-4 pr-4"
                          onClick={() => onCheckboxBtnClick("size", "M")}
                          active={filter.size.includes("M")}
                        >
                          <span>
                            <span style={{ fontSize: 18 }}>M</span>
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col md={3} sm={4}>
                      <ButtonGroup>
                        <Button
                          color="light"
                          type="button"
                          outline
                          className="waves-effect waves-light mb-2 pl-4 pr-4"
                          onClick={() => onCheckboxBtnClick("size", "L")}
                          active={filter.size.includes("L")}
                        >
                          <span>
                            <span style={{ fontSize: 18 }}>L</span>
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col md={3} sm={4}>
                      <ButtonGroup>
                        <Button
                          color="light"
                          type="button"
                          outline
                          className="waves-effect waves-light mb-2 pl-4 pr-4"
                          onClick={() => onCheckboxBtnClick("size", "XL")}
                          active={filter.size.includes("XL")}
                        >
                          <span>
                            <span style={{ fontSize: 18 }}>XL</span>
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col md={3} sm={4}>
                      <ButtonGroup>
                        <Button
                          color="light"
                          type="button"
                          outline
                          className="waves-effect waves-light mb-2 pl-4 pr-4"
                          onClick={() => onCheckboxBtnClick("size", "XXL")}
                          active={filter.size.includes("XXL")}
                        >
                          <span>
                            <span style={{ fontSize: 18 }}>XXL</span>
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <h5 className="font-size-14 mb-3 mt-2">Price</h5>
                  <Row>
                    <Col md={12}>
                      <div className="form-check mb-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="price"
                          checked={
                            filter.price.upperLimit === "" ? true : false
                          }
                          onChange={() =>
                            setFilter({
                              ...filter,
                              price: { upperLimit: "", lowerLimit: "" },
                            })
                          }
                        />
                        <Label className="form-check-label">All Range</Label>
                      </div>
                      <div className="form-check mb-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="price"
                          onChange={() =>
                            setFilter({
                              ...filter,
                              price: { upperLimit: 999, lowerLimit: 0 },
                            })
                          }
                        />
                        <Label className="form-check-label">₹0 - ₹999</Label>
                      </div>
                      <div className="form-check mb-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="price"
                          onChange={() =>
                            setFilter({
                              ...filter,
                              price: { upperLimit: 2999, lowerLimit: 1000 },
                            })
                          }
                        />
                        <Label className="form-check-label">
                          ₹1000 - ₹2999
                        </Label>
                      </div>
                      <div className="form-check mb-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="price"
                          onChange={() =>
                            setFilter({
                              ...filter,
                              price: { upperLimit: 10000, lowerLimit: 3000 },
                            })
                          }
                        />
                        <Label className="form-check-label">Above ₹3000</Label>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={9}>
              <Card>
                <CardBody>
                  <div>
                    <Row>
                      <Col md={6}>
                        <div className="mb-2">
                          <h5>Inventory</h5>
                        </div>
                      </Col>

                      <Col md={6}></Col>
                    </Row>

                    <Row className="no-gutters">
                      {loading ? (
                        <Container>
                          <Row>
                            <Col md={4}></Col>
                            <Col md={4}>
                              <div style={{ align: "center" }}>
                                <Spinner
                                  className="mr-2 mt-2"
                                  color="primary"
                                />
                              </div>
                            </Col>
                            <Col md={4}></Col>
                          </Row>
                        </Container>
                      ) : products ? (
                        products.length === 0 ? (
                          <h4>No Products Found</h4>
                        ) : (
                          products.map((p, i) => (
                            <Col xl={4} sm={6}>
                              <div className="product-box">
                                <div className="product-img">
                                  <Carousel>
                                    {p.variation.length !== 0
                                      ? p.variation[0].image.map((i) => (
                                          <Carousel.Item>
                                            <img
                                              className="d-block img-fluid mx-auto"
                                              src={i}
                                              alt=""
                                            />
                                          </Carousel.Item>
                                        ))
                                      : p.image.map((i) => (
                                          <Carousel.Item>
                                            <img
                                              className="d-block img-fluid mx-auto"
                                              src={i}
                                              alt=""
                                            />
                                          </Carousel.Item>
                                        ))}
                                  </Carousel>
                                </div>

                                <div className="text-center">
                                  <p className="font-size-12 mb-1">
                                    SKU: {p.sku}
                                  </p>
                                  <h5>
                                    <Link
                                      to={{
                                        pathname: `productDetails/${p._id}`,
                                        state: { product: p },
                                      }}
                                      className="text-dark"
                                    >
                                      {p.name}
                                    </Link>
                                  </h5>

                                  <h5 className="mt-3 mb-0 font-size-15 text-primary">
                                    MRP ₹ {p.mrp ? p.mrp : p.variation[0].mrp}
                                  </h5>
                                </div>
                              </div>
                            </Col>
                          ))
                        )
                      ) : null}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Products;
