/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Alert,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AvForm, AvField } from "availity-reactstrap-validation";

import axios from "axios";
import { getToken } from "../../utils/user";
import moment from "moment";

const Coupons = () => {
  let token = getToken();
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({
    _id: "",
    couponCode: "",
    expiredDate: "",
    discount: "",
  });

  const [editModal, setEditModal] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const { _id, couponCode, expiredDate, discount } = coupon;

  const getCoupons = async () => {
    setLoading(true);
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/coupon",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      setLoading(false);
      setCoupons(resp.data.coupons);
    }
  };

  const handleSubmit = async (event, errors, values) => {
    setEditLoading(true);
    try {
      let resp = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/coupon/${_id}`,
        values,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEditLoading(false);
      if (resp.data.message) {
        setEditError(false);
        setCoupon({
          _id: "",
          couponCode: "",
          expiredDate: "",
          discount: "",
        });
        getCoupons();
        setEditModal(false);
      }
    } catch (err) {
      setEditLoading(false);
      setEditError(err.response.data.error);
    }
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    getCoupons();
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={editModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setEditModal(false)}>
          Edit Coupon Details
        </ModalHeader>
        <ModalBody>
          {editError && <Alert color="danger">{editError}</Alert>}

          <div className="p-2 mt-3">
            <AvForm
              onSubmit={handleSubmit}
              className="form-horizontal"
              model={coupon}
            >
              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-price-tag-3-line auti-custom-input-icon"></i>
                <Label htmlFor="couponCode">Coupon Code</Label>
                <AvField
                  name="couponCode"
                  value={couponCode}
                  type="text"
                  className="form-control"
                  id="couponCode"
                  placeholder="Enter Coupon Code"
                />
              </FormGroup>

              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-calendar-event-line auti-custom-input-icon"></i>
                <Label htmlFor="expiredDate">Expired Date</Label>
                <AvField
                  name="expiredDate"
                  value={moment(new Date(expiredDate)).format("YYYY-MM-DD")}
                  type="date"
                  className="form-control"
                  id="expiredDate"
                  placeholder="Enter Expired Date"
                />
              </FormGroup>

              <FormGroup className="auth-form-group-custom mb-4">
                <i className="ri-percent-line auti-custom-input-icon"></i>
                <Label htmlFor="discount">Discount</Label>
                <AvField
                  name="discount"
                  value={discount}
                  type="number"
                  className="form-control"
                  id="discount"
                  placeholder="Enter Discount"
                />
              </FormGroup>

              <div className="text-center">
                <Button
                  color="primary"
                  className="mt-1 w-md waves-effect waves-light"
                  type="submit"
                >
                  {editLoading ? "Loading ..." : "Edit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="View Coupons"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "View Coupons", link: "/coupons" },
            ]}
          />

          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={12}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <div className="table-responsive">
                          <Table striped className=" mb-0">
                            <thead>
                              <tr>
                                <th>Sr no.</th>
                                <th>Coupon Code</th>
                                <th>Expired Date</th>
                                <th>Discount (%)</th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <tr>
                                  <th></th>
                                  <th></th>

                                  <th>
                                    <Spinner color="primary" />
                                  </th>

                                  <th></th>
                                  <th></th>
                                </tr>
                              ) : coupons && coupons.length === 0 ? (
                                <tr>
                                  <th></th>
                                  <th></th>

                                  <th>
                                    <h5>No coupon found!</h5>
                                  </th>

                                  <th></th>
                                  <th></th>
                                </tr>
                              ) : (
                                coupons &&
                                coupons.map((u, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{u.couponCode}</td>
                                    <td>
                                      {moment(new Date(u.expiredDate)).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>{u.discount}</td>

                                    <td>
                                      <Button
                                        color="warning"
                                        type="button"
                                        outline
                                        className="waves-effect waves-light mr-1"
                                        onClick={() => {
                                          setCoupon(u);
                                          setEditModal(true);
                                        }}
                                      >
                                        <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                        Edit
                                      </Button>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
                {/*}  <Col lg={1}></Col>*/}
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Coupons;
