/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Alert,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import { getToken } from "../../utils/user";
import axios from "axios";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Carousel from "react-bootstrap/Carousel";
import FormUpload from "./FormUpload";
import FormUploadEditProduct from "./FormUploadEditProduct";
import moment from "moment";

const ProductDetail = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const token = getToken();
  const [data, setData] = useState("");
  const [currentOption, setCurrentOption] = useState("");
  const [selectSize, setSelectSize] = useState("");
  const [price, setPrice] = useState(0);
  const [mrp, setMrp] = useState(0);
  const [stock, setStock] = useState(0);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isNew, setIsNew] = useState("");
  const [categories, setCategories] = useState([]);
  const [detailsForm, setDetailsForm] = useState("");
  const [variationNewForm, setVariationNewForm] = useState("");
  const [showVariation, setShowVariation] = useState(false);
  const [variationForm, setVariationForm] = useState("");
  const [variationImages, setVariationImages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [newVariation, setNewVariation] = useState(false);
  const [newVariationData, setNewVariationData] = useState("");
  const [newVariationImage, setNewVariationImage] = useState([]);

  const getCategories = async () => {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/category/fetch",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      setCategories(resp.data.categories);
    }
  };

  const addNewVariation = async () => {
    if (newVariationImage.length === 0) {
      return;
    }
    if (newVariationImage) {
      for (const key of Object.keys(newVariationImage)) {
        variationNewForm.append("image", newVariationImage[key]);
      }
    }
    let resp = await axios.put(
      process.env.REACT_APP_BACKEND_URL + `/product/variationDetails/${id}`,
      variationNewForm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (resp.data.message) {
      setVariationNewForm(new FormData());
      setSuccess("Variation added successfully!");
      setTimeout(() => {
        setSuccess(false);
        fetchSingleProduct(data._id);
        setEditModal(false);
        setNewVariation(false);
      }, 2000);
    }
  };

  const editBasicDetails = async () => {
    setLoading(true);
    detailsForm.set("new", isNew);
    if (selectedCategories) {
      selectedCategories.map((p, index) =>
        detailsForm.set(`category[${index}]`, p.value)
      );
    }

    let resp = await axios.put(
      process.env.REACT_APP_BACKEND_URL +
        `/product/edit/basicDetails/${data._id}`,
      detailsForm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (resp.data.message) {
      setLoading(false);
      fetchSingleProduct(data._id);
      setEditModal(false);
    }
  };

  const fetchSingleProduct = async (id) => {
    let resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + `/product/single/${id}`
    );
    if (resp.data.message) {
      setData(resp.data.product);
      setIsNew(resp.data.product.new);
      let arr = [];
      resp.data.product.category.map((dt) => {
        let obj = {
          value: dt,
          label: dt,
        };
        arr.push(obj);
      });
      setSelectedCategories(arr);
      if (resp.data.product.price) {
        setPrice(resp.data.product.price);
      }
      if (resp.data.product.stock) {
        setStock(resp.data.product.stock);
      }
      if (resp.data.product.variation.length !== 0) {
        setCurrentOption(resp.data.product.variation[0]);
        if (resp.data.product.variation[0]?.price) {
          setPrice(resp.data.product.variation[0]?.price);
          setMrp(resp.data.product.variation[0]?.mrp);
        }

        if (resp.data.product.variation[0].stock) {
          setStock(resp.data.product.variation[0].stock);
        }
        if (resp.data.product.variation[0].size.length !== 0) {
          setSelectSize(resp.data.product.variation[0].size[0]);
          setStock(resp.data.product.variation[0].size[0].stock);
        }
      }
    }
  };

  const editVariations = async () => {
    if (variationImages) {
      for (const key of Object.keys(variationImages)) {
        variationForm.append("image", variationImages[key]);
      }
    }
    let resp = await axios.put(
      process.env.REACT_APP_BACKEND_URL +
        `/product/edit/variationDetails/${data._id}/${currentOption._id}`,
      variationForm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (resp.data.message) {
      setVariationForm(new FormData());
      setSuccess("Variation updated successfully!");
      setTimeout(() => {
        setSuccess(false);
        fetchSingleProduct(data._id);
        setEditModal(false);
      }, 2000);
    }
  };

  useEffect(() => {
    getCategories();
    setDetailsForm(new FormData());
    setVariationForm(new FormData());
    setVariationNewForm(new FormData());
    fetchSingleProduct(id);
    setNewVariationData("");
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={deleteModal} centered={true} scrollable={true}>
        <ModalHeader toggle={() => setDeleteModal(false)}>
          Delete Product
        </ModalHeader>
        <ModalBody>
          <div className="p-2">
            <AvForm
              onValidSubmit={async () => {
                setDeleteLoading(true);
                try {
                  let resp = await axios.delete(
                    process.env.REACT_APP_BACKEND_URL +
                      `/product/delete/${data._id}`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  setDeleteLoading(false);
                  if (resp.data.message) {
                    setDeleteModal(false);
                    history.push({ pathname: "/products" });
                  }
                } catch (err) {
                  setDeleteLoading(false);
                  setDeleteModal(false);
                }
              }}
              className="form-horizontal"
            >
              <h4>Are you sure you want to delete this product?</h4>

              <div className="text-center">
                <Button
                  color="danger"
                  className="mt-1 w-md waves-effect waves-light mr-2"
                  type="submit"
                >
                  {deleteLoading ? "Loading ..." : "Delete"}
                </Button>
                <Button
                  color="dark"
                  className="mt-1 w-md waves-effect waves-light"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={editModal} size="xl" centered={true} scrollable={true}>
        <ModalHeader
          toggle={() => {
            setEditModal(false);
            setNewVariation(false);
          }}
        >
          Edit Product
        </ModalHeader>
        <ModalBody>
          {success && (
            <Col md={12}>
              <Alert color="success">{success}</Alert>
            </Col>
          )}
          <AvForm>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor="name">SKU</Label>
                  <Input
                    id="sku"
                    name="sku"
                    type="text"
                    value={data.sku}
                    className="form-control"
                    onChange={(e) => {
                      detailsForm.set("sku", e.target.value);
                      setData({ ...data, sku: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor="name">Name</Label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    value={data.name}
                    className="form-control"
                    onChange={(e) => {
                      detailsForm.set("name", e.target.value);
                      setData({ ...data, name: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label htmlFor="description">Description</Label>
                  <Input
                    id="description"
                    name="description"
                    value={data.description}
                    type="text"
                    rows={3}
                    className="form-control"
                    onChange={(e) => {
                      detailsForm.set("description", e.target.value);
                      setData({ ...data, description: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="control-label">
                    Categories (Select Multiple)
                  </Label>
                  <Select
                    className="select2 select2-multiple"
                    value={selectedCategories}
                    isMulti
                    options={
                      categories &&
                      categories.map((c) => {
                        return { value: c.name, label: c.name };
                      })
                    }
                    onChange={(v) => {
                      setSelectedCategories(v);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor="rating">Rating</Label>
                  <Input
                    id="rating"
                    name="rating"
                    type="number"
                    value={data.rating}
                    className="form-control"
                    onChange={(e) => {
                      detailsForm.set("rating", e.target.value);
                      setData({ ...data, rating: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label htmlFor="discount">Discount (%)</Label>
                  <Input
                    id="discount"
                    name="discount"
                    type="number"
                    value={data.discount}
                    className="form-control"
                    onChange={(e) => {
                      detailsForm.set("discount", e.target.value);
                      setData({ ...data, discount: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label htmlFor="offerEnd">Offer End</Label>
                  <Input
                    id="offerEnd"
                    name="offerEnd"
                    type="date"
                    value={
                      data.offerEnd &&
                      moment(new Date(data.offerEnd)).format("YYYY-MM-DD")
                    }
                    className="form-control"
                    onChange={(e) => {
                      detailsForm.set("offerEnd", e.target.value);
                      setData({ ...data, offerEnd: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="model">New</Label>
                  <div className="custom-control custom-switch mb-2" dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input p-3"
                      id="customSwitch1"
                      defaultChecked={isNew}
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="customSwitch1"
                      onClick={(e) => setIsNew(!isNew)}
                    >
                      Toggle the switch to change new arrival setting
                    </Label>
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <div className="mt-2">
                  <Button
                    color="primary"
                    type="submit"
                    className="waves-effect waves-light"
                    onClick={async (e) => {
                      e.preventDefault();
                      editBasicDetails();
                    }}
                  >
                    {loading ? "Loading" : "Edit Basic Details"}
                  </Button>
                </div>
              </Col>
              <Col md={12} className="mr-2">
                <div className="product-color mt-1 mr-2">
                  <hr className="my-4" />
                  {data && data.variation.length !== 0 && (
                    <h4>Variation Info</h4>
                  )}

                  {data &&
                    data.variation.length !== 0 &&
                    data.variation.map((p) => (
                      <Link
                        className=""
                        onClick={() => {
                          setCurrentOption(p);
                          setShowVariation(true);
                        }}
                      >
                        <div
                          className="product-color-item"
                          style={{
                            backgroundColor: `${p.color}`,
                            width: 30,
                            height: 30,
                            borderRadius: "50%",
                          }}
                        />
                      </Link>
                    ))}
                </div>
              </Col>
              {showVariation && (
                <AvForm>
                  <Row className="mt-2">
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="color">Color</Label>
                        <Input
                          id="color"
                          value={currentOption.color}
                          name="color"
                          type="color"
                          className="form-control"
                          onChange={(e) => {
                            variationForm.set("color", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              color: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="stock">Stock</Label>
                        <Input
                          id="stock"
                          value={currentOption.stock}
                          name="stock"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            variationForm.set("stock", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              stock: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="price">Cost Price (Rs)</Label>
                        <Input
                          id="price"
                          value={currentOption.price}
                          name="price"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            variationForm.set("price", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              price: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="mrp">Market Price (Rs)</Label>
                        <Input
                          id="mrp"
                          value={currentOption.mrp}
                          name="mrp"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            variationForm.set("mrp", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              mrp: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="XS">XS</Label>
                        <Input
                          id="XS"
                          name="XS"
                          value={
                            currentOption.size &&
                            currentOption.size.find((s) => s.name === "XS")
                              ?.stock
                          }
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            let size = currentOption.size;
                            let index = size.findIndex((s) => s.name === "XS");
                            if (index === -1)
                              size.push({ name: "XS", stock: e.target.value });
                            else size[index].stock = e.target.value;
                            variationForm.set("XS", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              size,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="S">S</Label>
                        <Input
                          id="S"
                          name="S"
                          type="number"
                          className="form-control"
                          value={
                            currentOption.size &&
                            currentOption.size.find((s) => s.name === "S")
                              ?.stock
                          }
                          onChange={(e) => {
                            let size = currentOption.size;
                            let index = size.findIndex((s) => s.name === "S");
                            if (index === -1)
                              size.push({ name: "S", stock: e.target.value });
                            else size[index].stock = e.target.value;
                            variationForm.set("S", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              size,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="M">M</Label>
                        <Input
                          id="M"
                          name="M"
                          type="number"
                          className="form-control"
                          value={
                            currentOption.size &&
                            currentOption.size.find((s) => s.name === "M")
                              ?.stock
                          }
                          onChange={(e) => {
                            let size = currentOption.size;
                            let index = size.findIndex((s) => s.name === "M");
                            if (index === -1)
                              size.push({ name: "M", stock: e.target.value });
                            else size[index].stock = e.target.value;
                            variationForm.set("M", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              size,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="L">L</Label>
                        <Input
                          id="L"
                          name="L"
                          type="number"
                          className="form-control"
                          value={
                            currentOption.size &&
                            currentOption.size.find((s) => s.name === "L")
                              ?.stock
                          }
                          onChange={(e) => {
                            let size = currentOption.size;
                            let index = size.findIndex((s) => s.name === "L");
                            if (index === -1)
                              size.push({ name: "L", stock: e.target.value });
                            else size[index].stock = e.target.value;
                            variationForm.set("L", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              size,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="XL">XL</Label>
                        <Input
                          id="XL"
                          name="XL"
                          type="number"
                          className="form-control"
                          value={
                            currentOption.size &&
                            currentOption.size.find((s) => s.name === "XL")
                              ?.stock
                          }
                          onChange={(e) => {
                            let size = currentOption.size;
                            let index = size.findIndex((s) => s.name === "XL");
                            if (index === -1)
                              size.push({ name: "XL", stock: e.target.value });
                            else size[index].stock = e.target.value;
                            variationForm.set("XL", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              size,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="XXL">XXL</Label>
                        <Input
                          id="XXL"
                          name="XXL"
                          type="number"
                          className="form-control"
                          value={
                            currentOption.size &&
                            currentOption.size.find((s) => s.name === "XXL")
                              ?.stock
                          }
                          onChange={(e) => {
                            let size = currentOption.size;
                            let index = size.findIndex((s) => s.name === "XXL");
                            if (index === -1)
                              size.push({ name: "XXL", stock: e.target.value });
                            else size[index].stock = e.target.value;
                            variationForm.set("XXL", e.target.value);
                            setCurrentOption({
                              ...currentOption,
                              size,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormUploadEditProduct
                        label="Product Images"
                        setImages={setVariationImages}
                      />
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <div className="text-center mt-4">
                        <Button
                          color="info"
                          outline
                          type="submit"
                          className="mr-2 waves-effect waves-light"
                          onClick={async (e) => {
                            e.preventDefault();
                            editVariations();
                          }}
                        >
                          Edit Variation
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              )}
              <Col md={4}></Col>
              <Col md={12} className="mr-2">
                <div className="product-color mt-1 mr-2 mb-4">
                  <hr className="my-4" />
                  <Button
                    onClick={() => {
                      setNewVariation(true);
                    }}
                  >
                    Add New Variation
                  </Button>
                </div>
              </Col>
              {/* Add new variation Block */}
              {newVariation && (
                <AvForm>
                  <Row>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="color">New Color</Label>
                        <Input
                          id={"color"}
                          name={"color"}
                          type="color"
                          className="form-control"
                          onChange={(e) => {
                            variationNewForm.set("color", e.target.value);
                            setNewVariationData({
                              ...newVariationData,
                              color: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="stock">Stock</Label>
                        <Input
                          id="stock"
                          name="stock"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            variationNewForm.set("stock", e.target.value);

                            setNewVariationData({
                              ...newVariationData,
                              stock: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="price">Price</Label>
                        <Input
                          id="price"
                          name="price"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            variationNewForm.set("price", e.target.value);

                            setNewVariationData({
                              ...newVariationData,
                              price: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="mrp">Selling Price (MRP)</Label>
                        <Input
                          id="mrp"
                          name="mrp"
                          type="number"
                          className="form-control"
                          required
                          onChange={(e) => {
                            variationNewForm.set("mrp", e.target.value);

                            setNewVariationData({
                              ...newVariationData,
                              mrp: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="XS">XS</Label>
                        <Input
                          id="XS"
                          name="XS"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            setNewVariationData({
                              ...newVariationData,
                              XS: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="S">S</Label>
                        <Input
                          id="S"
                          name="S"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            setNewVariationData({
                              ...newVariationData,
                              S: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="M">M</Label>
                        <Input
                          id="M"
                          name="M"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            setNewVariationData({
                              ...newVariationData,
                              M: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="L">L</Label>
                        <Input
                          id="L"
                          name="L"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            setNewVariationData({
                              ...newVariationData,
                              L: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="XL">XL</Label>
                        <Input
                          id="XL"
                          name="XL"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            setNewVariationData({
                              ...newVariationData,
                              XL: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label htmlFor="XXL">XXL</Label>
                        <Input
                          id="XXL"
                          name="XXL"
                          type="number"
                          className="form-control"
                          onChange={(e) => {
                            setNewVariationData({
                              ...newVariationData,
                              XXL: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormUploadEditProduct
                        label="Product Images"
                        setImages={setNewVariationImage}
                      />
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <div className="text-center mt-4">
                        <Button
                          color="info"
                          outline
                          type="submit"
                          className="mr-2 waves-effect waves-light"
                          onClick={async (e) => {
                            e.preventDefault();
                            addNewVariation();
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              )}
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Product Details"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Product Details", link: "#" },
            ]}
          />

          {data && (
            <>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl={5}>
                          <div className="product-detail">
                            <Row>
                              <Col md={12} xs={12}>
                                <div className="product-img">
                                  <Carousel>
                                    {currentOption
                                      ? currentOption.image.map((i) => (
                                          <Carousel.Item>
                                            <img
                                              className="d-block img-fluid mx-auto"
                                              src={i}
                                              alt=""
                                              style={{ height: 530 }}
                                            />
                                          </Carousel.Item>
                                        ))
                                      : data.image.map((i) => (
                                          <Carousel.Item>
                                            <img
                                              className="d-block img-fluid mx-auto"
                                              src={i}
                                              alt=""
                                              style={{ height: 530 }}
                                            />
                                          </Carousel.Item>
                                        ))}
                                  </Carousel>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col xl={7}>
                          <div className="mt-4 mt-xl-3">
                            <Row>
                              <Col md={8}>
                                <Link to="#" className="text-primary">
                                  {data.category.map((c) => (
                                    <span>
                                      {c}
                                      {","}
                                      {"  "}
                                    </span>
                                  ))}
                                </Link>
                                <h4 className="mt-1 mb-3">
                                  Product Name - {data.name}
                                </h4>
                                <h5 className="mt-2 text-primary">
                                  MRP ₹{mrp}
                                </h5>
                                <h6 className="mt-1 text-secondary">
                                  Cost Price ₹{price}
                                </h6>
                              </Col>
                              <Col md={4}>
                                <Row className="text-center mt-2">
                                  <Col sm={6}>
                                    <Button
                                      color="warning"
                                      outline
                                      block
                                      type="button"
                                      className="waves-effect waves-light mt-2 mr-1"
                                      onClick={(e) => setEditModal(true)}
                                    >
                                      <i className="ri-pencil-fill align-middle mr-2"></i>{" "}
                                      Edit
                                    </Button>
                                  </Col>
                                  <Col sm={6}>
                                    <Button
                                      color="danger"
                                      outline
                                      block
                                      type="button"
                                      className="btn-block waves-effect  mt-2 waves-light"
                                      onClick={(e) => setDeleteModal(true)}
                                    >
                                      <i className="ri-delete-bin-fill align-middle mr-2"></i>
                                      Delete
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={12}>
                                <div className="product-color mt-1 mr-2">
                                  <hr className="my-4" />
                                  <h3 className="font-size-18 mt-2">
                                    Product Details
                                  </h3>
                                  {data.variation.length !== 0 &&
                                    data.variation.map((p) => (
                                      <Link
                                        className=""
                                        onClick={() => {
                                          setCurrentOption(p);
                                          if (p.price) {
                                            setPrice(p.price);
                                            setMrp(p.mrp);
                                          }
                                          if (p.stock) {
                                            setStock(p.stock);
                                          }
                                        }}
                                      >
                                        <div
                                          className="product-color-item"
                                          style={{
                                            backgroundColor: `${p.color}`,
                                            width: 30,
                                            height: 30,
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </Link>
                                    ))}
                                </div>
                              </Col>

                              <Row>
                                <Col md={12}>
                                  <div className="product-color mt-1 mr-2">
                                    {currentOption &&
                                      currentOption.size.length !== 0 &&
                                      currentOption.size.map((p) => (
                                        <Link
                                          className=""
                                          onClick={() => {
                                            setSelectSize(p);
                                            setStock(p.stock);
                                          }}
                                        >
                                          <div
                                            className="product-color-item"
                                            style={{ width: 40, height: 40 }}
                                          >
                                            {p.name}
                                          </div>
                                        </Link>
                                      ))}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="m-2">
                                <Col md={6}>
                                  <Label htmlFor="name">SKU</Label>
                                  <Input
                                    id="sku"
                                    name="sku"
                                    type="text"
                                    disabled={true}
                                    value={data.sku}
                                  />
                                </Col>
                                <Col md={6}>
                                  <Label htmlFor="name">Discount %</Label>
                                  <Input
                                    id="sku"
                                    name="sku"
                                    type="text"
                                    disabled={true}
                                    value={
                                      data.discount === undefined ||
                                      data.discount === null
                                        ? "NA"
                                        : data.discount + " %"
                                    }
                                  />
                                </Col>
                                <Col md={6} className="mt-4">
                                  <Label htmlFor="name">Rating</Label>
                                  <Input
                                    id="sku"
                                    name="sku"
                                    type="text"
                                    disabled={true}
                                    value={data.rating}
                                  />
                                </Col>
                                <Col md={6} className="mt-4">
                                  <Label htmlFor="name">Stock Available</Label>
                                  <Input
                                    id="sku"
                                    name="sku"
                                    type="text"
                                    disabled={true}
                                    value={stock}
                                  />
                                </Col>
                                <Col md={6} className="mt-4">
                                  <Label htmlFor="name">New Arrival</Label>
                                  <Input
                                    id="sku"
                                    name="sku"
                                    type="text"
                                    disabled={true}
                                    value={data.new === true ? "YES" : "NO"}
                                  />
                                </Col>
                                <Col md={6} className="mt-4">
                                  <Label htmlFor="name">Offer End</Label>
                                  <Input
                                    id="sku"
                                    name="sku"
                                    type="text"
                                    disabled={true}
                                    value={
                                      data.offerEnd !== undefined
                                        ? moment(
                                            new Date(data.offerEnd)
                                          ).format("DD-MM-YYYY")
                                        : "NA"
                                    }
                                  />
                                </Col>
                                <Col md={12} className="mt-4">
                                  <Label htmlFor="name">
                                    Product Description
                                  </Label>
                                  <Input
                                    id="sku"
                                    name="sku"
                                    type="text"
                                    disabled={true}
                                    value={data.description}
                                  />
                                </Col>
                              </Row>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductDetail;
