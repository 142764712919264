export const getToken = () => {
  let token = JSON.parse(localStorage.getItem("authUser")).token;
  return token;
};

export const getRole = () => {
  let role = JSON.parse(localStorage.getItem("authUser")).user.role;
  return role;
};

export const getPermission = () => {
  let permissions = JSON.parse(localStorage.getItem("authUser")).permissions;
  return permissions;
};

export const hasResource = (resource) => {
  let user = JSON.parse(localStorage.getItem("authUser"));
  let role = user.user.role;
  if (role === "SuperAdmin") {
    return true;
  }
  let permissions = user.permissions;
  if (permissions.filter((p) => p.resource === resource).length === 0) {
    return false;
  }
  return true;
};

export const isPermitted = (permit) => {
  let user = JSON.parse(localStorage.getItem("authUser"));
  let role = user.user.role;
  if (role === "SuperAdmin") {
    return true;
  }
  let permissions = user.permissions;
  if (permissions.filter((p) => p.name === permit).length === 0) {
    return false;
  }
  return true;
};

export const verifyConfig = (config) => {
  let configurations = JSON.parse(
    localStorage.getItem("authUser")
  ).configurations;
  if (!configurations) return false;
  if (!configurations[config]) return false;
  return true;
};
