/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { getToken } from "../../utils/user";
import Breadcrumbs from "../../components/Common/Breadcrumb";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import axios from "axios";

import { useHistory } from "react-router-dom";

const Role = () => {
  const history = useHistory();
  let token = getToken();

  const [role, setRole] = useState({
    roleName: "",
    product: [],
    category: [],
    order: [],
    orderStatus: [],
    customer: [],
    cart: [],
    eyeTest: [],
    coupon: [],
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const {
    roleName,
    product,
    category,
    order,
    customer,
    orderStatus,
    cart,
    eyeTest,
    coupon,
  } = role;

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      let resp = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/role",
        role,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoading(false);
      if (resp.data.message) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          history.push("/roles");
        }, 3000);
        setError(false);
        setRole({
          roleName: "",
          product: [],
          category: [],
          order: [],
          customer: [],
          orderStatus: [],
          cart: [],
          eyeTest: [],
          coupon: [],
        });
      }
    } catch (err) {
      setLoading(false);
      setSuccess(false);
      setError(err.response.data.error);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const getPermissions = async () => {
    let resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/role/permissions",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (resp.data.message) {
      console.log("pre", resp.data.permissions);
      setPermissions(resp.data.permissions);
    }
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    getPermissions();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Create Role"
            breadcrumbItems={[
              { title: "Dashboard", link: "/" },
              { title: "Create Role", link: "/role" },
            ]}
          />

          <div>
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col lg={2}></Col>
                <Col lg={8}>
                  <div className="p-4 d-flex">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <Col lg={9}>
                          <div>
                            <div className="text-center">
                              <h4 className="font-size-18 mt-4">Create Role</h4>
                              <p className="text-muted">
                                Create role and assign permissions.
                              </p>
                            </div>

                            {success && (
                              <Alert color="success">
                                Role created Successfully.
                              </Alert>
                            )}

                            {error && <Alert color="danger">{error}</Alert>}

                            <div className="p-2 mt-5">
                              <AvForm
                                onValidSubmit={handleSubmit}
                                className="form-horizontal"
                              >
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-medal-line auti-custom-input-icon"></i>
                                  <Label htmlFor="roleName">Role Name</Label>
                                  <AvField
                                    name="roleName"
                                    value={roleName}
                                    type="text"
                                    className="form-control"
                                    id="roleName"
                                    placeholder="Enter Role Name"
                                    onChange={(e) =>
                                      setRole({
                                        ...role,
                                        roleName: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>

                                <Row>
                                  {permissions &&
                                    permissions.map((p, i) => {
                                      if (p.resource !== "orderStatus") {
                                        return (
                                          <Col
                                            lg={6}
                                            className="mb-1"
                                            style={
                                              i % 2 !== 0
                                                ? {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                  }
                                                : {
                                                    textAlign: "left",
                                                  }
                                            }
                                          >
                                            <Label htmlFor={p.resource}>
                                              {p.resource
                                                .charAt(0)
                                                .toUpperCase() +
                                                p.resource.slice(1)}
                                            </Label>
                                            <AvCheckboxGroup name={p.resource}>
                                              {p.actions.map((a) => (
                                                <AvCheckbox
                                                  label={a.name}
                                                  value={a.id}
                                                  onChange={(e) => {
                                                    if (
                                                      p.resource === "product"
                                                    ) {
                                                      product.indexOf(
                                                        e.target.value
                                                      ) === -1
                                                        ? product.push(
                                                            e.target.value
                                                          )
                                                        : product.splice(
                                                            product.indexOf(
                                                              e.target.value
                                                            ),
                                                            1
                                                          );
                                                    } else if (
                                                      p.resource === "category"
                                                    ) {
                                                      category.indexOf(
                                                        e.target.value
                                                      ) === -1
                                                        ? category.push(
                                                            e.target.value
                                                          )
                                                        : category.splice(
                                                            category.indexOf(
                                                              e.target.value
                                                            ),
                                                            1
                                                          );
                                                    } else if (
                                                      p.resource === "order"
                                                    ) {
                                                      order.indexOf(
                                                        e.target.value
                                                      ) === -1
                                                        ? order.push(
                                                            e.target.value
                                                          )
                                                        : order.splice(
                                                            order.indexOf(
                                                              e.target.value
                                                            ),
                                                            1
                                                          );
                                                    } else if (
                                                      p.resource === "customer"
                                                    ) {
                                                      customer.indexOf(
                                                        e.target.value
                                                      ) === -1
                                                        ? customer.push(
                                                            e.target.value
                                                          )
                                                        : customer.splice(
                                                            customer.indexOf(
                                                              e.target.value
                                                            ),
                                                            1
                                                          );
                                                    } else if (
                                                      p.resource === "cart"
                                                    ) {
                                                      cart.indexOf(
                                                        e.target.value
                                                      ) === -1
                                                        ? cart.push(
                                                            e.target.value
                                                          )
                                                        : cart.splice(
                                                            cart.indexOf(
                                                              e.target.value
                                                            ),
                                                            1
                                                          );
                                                    } else if (
                                                      p.resource === "coupon"
                                                    ) {
                                                      coupon.indexOf(
                                                        e.target.value
                                                      ) === -1
                                                        ? coupon.push(
                                                            e.target.value
                                                          )
                                                        : coupon.splice(
                                                            coupon.indexOf(
                                                              e.target.value
                                                            ),
                                                            1
                                                          );
                                                    } else {
                                                      eyeTest.indexOf(
                                                        e.target.value
                                                      ) === -1
                                                        ? eyeTest.push(
                                                            e.target.value
                                                          )
                                                        : eyeTest.splice(
                                                            eyeTest.indexOf(
                                                              e.target.value
                                                            ),
                                                            1
                                                          );
                                                    }
                                                  }}
                                                />
                                              ))}
                                            </AvCheckboxGroup>
                                          </Col>
                                        );
                                      } else {
                                        return (
                                          <Col lg={12} className="mb-1">
                                            <Label htmlFor={p.resource}>
                                              Order Status
                                            </Label>

                                            <AvCheckboxGroup name={p.resource}>
                                              <Row>
                                                {p.actions.map((a, index) => {
                                                  if (index <= 8) {
                                                    return (
                                                      <Col lg={6}>
                                                        <AvCheckbox
                                                          label={a.name}
                                                          value={a.id}
                                                          onChange={(e) => {
                                                            orderStatus.indexOf(
                                                              e.target.value
                                                            ) === -1
                                                              ? orderStatus.push(
                                                                  e.target.value
                                                                )
                                                              : orderStatus.splice(
                                                                  orderStatus.indexOf(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                                  1
                                                                );
                                                          }}
                                                        />
                                                      </Col>
                                                    );
                                                  } else {
                                                    return (
                                                      <Col lg={6}>
                                                        <AvCheckbox
                                                          label={a.name}
                                                          value={a.id}
                                                          onChange={(e) => {
                                                            orderStatus.indexOf(
                                                              e.target.value
                                                            ) === -1
                                                              ? orderStatus.push(
                                                                  e.target.value
                                                                )
                                                              : orderStatus.splice(
                                                                  orderStatus.indexOf(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                                  1
                                                                );
                                                          }}
                                                        />
                                                      </Col>
                                                    );
                                                  }
                                                })}
                                              </Row>
                                            </AvCheckboxGroup>
                                          </Col>
                                        );
                                      }
                                    })}
                                </Row>

                                <div className="text-center">
                                  <Button
                                    color="primary"
                                    className="mt-1 w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    {loading ? "Loading ..." : "Create"}
                                  </Button>
                                </div>
                              </AvForm>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={2}></Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Role;
